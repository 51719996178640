import useSWR, { mutate } from 'swr'
import { PeriodsResponse } from 'types/periods'
import { fetchWithToken } from './http'

export function usePeriods(token: string, organizationId: string | null, shouldFetch: boolean = true) {
  const shouldFetchData = token != null && token.trim() !== '' && shouldFetch
  const params = new Array<[string, string]>()
  if (organizationId) {
    params.push(['organizationId', organizationId])
  }

  const { data, error, isValidating } = useSWR(
    shouldFetchData ? [`periods`, token, params] : null,
    ([url, token, params]) => fetchWithToken(url, token, params.length > 0 ? params : null),
    {
      suspense: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  )

  const handleRefetch = () => {
    mutate(['periods', token, params], fetchWithToken('periods', token!, params.length > 0 ? params : null))
  }

  return {
    periods: data as PeriodsResponse,
    isLoadingPeriods: !data && isValidating,
    isError: error,
    handleRefetch,
  }
}
