import { Box, CircularProgress, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material'
import CompPlanContent from 'components/CompPlanContent'
import SimpleTitle from 'components/SimpleTitle'
import useAuth from 'hooks/useAuth'
import { useFetchLedgerAccounts } from 'hooks/useLedgerAccounts'
import { usePlan } from 'hooks/usePlan'
import { useFetchUser } from 'hooks/useUsers'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { LedgerAccount } from 'types/ledger-account'
import { UserProfile } from 'types/user-profile'
import EmployeeDashboard from '../EmployeeDashboard'

const EmployeePlanView: React.FC = () => {
  const theme = useTheme()
  const { token } = useAuth()
  const { userId: paramUserId } = useParams()

  const [view, setView] = useState('dashboard')
  const [rootOrganizationId, setRootOrganizationId] = useState('')
  const [profileInitialized, setProfileInitialized] = useState(false)
  const [compPlanInitialized, setCompPlanInitialized] = useState(false)
  const [ledgerAccounts, setLedgerAccounts] = useState<LedgerAccount[] | undefined>([])

  const { userProfile, isLoading: userLoading } = useFetchUser(
    token != null ? token : '',
    paramUserId ?? null,
    null,
    paramUserId != null && token != null
  )

  const { compPlan, isLoading: planLoading } = usePlan(
    token != null ? token : '',
    userProfile?.user?.id,
    null,
    profileInitialized && userProfile?.user?.id != null
  )

  const { ledgerAccounts: fetchedLedgerAccounts, isLoading: isLoadingLedgerAccounts } = useFetchLedgerAccounts(
    rootOrganizationId,
    token != null ? token : '',
    rootOrganizationId !== '' && token !== null && compPlanInitialized
  )

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, newView: string) => {
    if (newView !== null) {
      setView(newView)
    }
  }

  const isLoading = userLoading || planLoading || isLoadingLedgerAccounts || !profileInitialized

  useEffect(() => {}, [paramUserId])

  useEffect(() => {
    if (userProfile) {
      setView('dashboard')
      setProfileInitialized(true)
    }
  }, [userProfile])

  useEffect(() => {
    if (compPlan) {
      setRootOrganizationId(compPlan.plan.rootOrganizationId)
      setCompPlanInitialized(true)
    }
  }, [compPlan])

  useEffect(() => {
    if (fetchedLedgerAccounts) {
      setLedgerAccounts(fetchedLedgerAccounts)
    }
  }, [fetchedLedgerAccounts])

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>
    )
  }

  const title = (profile: UserProfile | undefined) => {
    if (!profile) {
      return ''
    }

    if (view === 'dashboard') {
      return `${profile.user.firstName} ${profile.user.lastName}'s Dashboard`
    } else {
      return `${profile.user.firstName} ${profile.user.lastName}'s Compensation Plan`
    }
  }

  const renderContent = () => {
    if (!compPlan || !userProfile) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
          <div>Error: Unable to load user data</div>
        </Box>
      )
    }

    if (view === 'dashboard') {
      return (
        <EmployeeDashboard
          key="employee-dashboard"
          user={userProfile?.user}
          plan={compPlan}
          theme={theme}
          status={planLoading ? 0 : 200}
        />
      )
    } else {
      return (
        <>
          <CompPlanContent
            rootOrganizationId={rootOrganizationId}
            token={token!}
            user={userProfile?.user}
            compPlan={compPlan}
            theme={theme}
            ledgerAccounts={ledgerAccounts}
          />
        </>
      )
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <SimpleTitle title={title(userProfile)} />
        <ToggleButtonGroup value={view} exclusive onChange={handleViewChange} aria-label="View toggle">
          <ToggleButton value="dashboard" aria-label="Dashboard View">
            Dashboard
          </ToggleButton>
          <ToggleButton value="plan" aria-label="Plan View">
            Plan
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {renderContent()}
    </div>
  )
}

export default EmployeePlanView
