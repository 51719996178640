import { Autocomplete, CircularProgress, Grid, InputLabel, Stack, TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import React, { FC, useEffect, useState } from 'react'

import SingleOrganizationSelector from 'components/SingleOrganizationSelector'
import { useCreatePlanContext } from 'contexts/CreatePlanContextProvider'
import useAuth from 'hooks/useAuth'
import { useFetchUser, useSearchUsers } from 'hooks/useUsers'
import { CompensationPlan } from 'types/comp-plan'
import { UserRole } from 'types/role'
import { User } from 'types/user'
import { getMemberRole, UserProfile } from 'types/user-profile'

const EmployeeDetailForm: FC = () => {
  // Context and Auth
  const { token } = useAuth()
  const { compensationTemplate, plan, setPlan, mode, setEmployeeName, originalEffectiveDate, withUserId } =
    useCreatePlanContext()

  // State
  const [profile, setProfile] = useState<UserProfile>({
    roles: [] as UserRole[],
    user: { firstName: '', lastName: '', position: '' } as User,
  } as UserProfile)
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState<string>('')
  const [effectiveDate, setEffectiveDate] = useState<dayjs.Dayjs | null>(null)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [hasInitializedEffectiveDate, setHasInitializedEffectiveDate] = useState(false)

  // Fetching Users
  const { users, isLoading: userSearchIsLoading } = useSearchUsers(
    token!,
    {
      organizationIds: compensationTemplate?.template.organizationIds,
      perPage: 1000,
    },
    compensationTemplate != null && compensationTemplate.template.organizationIds != null
  )

  const userId = withUserId ? withUserId : plan && plan.userId ? plan.userId : null
  const { userProfile, isLoading: userIsLoading } = useFetchUser(
    token!,
    userId,
    [['explicitRolesOnly', 'true']],
    !!userId
  )

  // Effects
  useEffect(() => {
    if (!userIsLoading && !userSearchIsLoading) {
      setIsDataLoaded(true)
    }
  }, [userIsLoading, userSearchIsLoading, userProfile])

  useEffect(() => {
    if (!hasInitializedEffectiveDate) {
      if (plan.effectiveDate) {
        setEffectiveDate(dayjs(plan.effectiveDate))
      } else {
        setEffectiveDate(dayjs())
      }
      setHasInitializedEffectiveDate(true)
    }
  }, [plan.id])

  useEffect(() => {
    let tempPlan = { ...plan }
    tempPlan.organizationId = ''
    if (effectiveDate) {
      tempPlan.effectiveDate = effectiveDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    } else {
      tempPlan.effectiveDate = ''
    }
    setPlan(tempPlan)
  }, [plan, setPlan])

  useEffect(() => {
    console.log('userProfile', userProfile)
    if (userProfile && userProfile.roles && userProfile.roles.length > 0) {
      console.log('userProfile has roles')
      const memberRole = getMemberRole(userProfile)
      console.log('memberRole', memberRole)
      if (memberRole) {
        console.log('userProfile has member role', memberRole.organization.id)
        setOrganizationHandler(memberRole.organization.id)
      }
    }
  }, [userProfile, selectedUser])

  useEffect(() => {
    if (isDataLoaded && userProfile?.user) {
      setEmployeeName(`${userProfile.user.firstName} ${userProfile.user.lastName}`)
      if (!plan.position) {
        setPlan((prevPlan: CompensationPlan) => ({ ...prevPlan, position: '' }))
      }
      if (!plan.userId) {
        setPlan((prevPlan: CompensationPlan) => ({
          ...prevPlan,
          userId: userProfile.user.id,
          position: userProfile.user.position,
        }))
      }
      setProfile(userProfile)
    }
  }, [isDataLoaded, userProfile, plan.id, plan.position, setEmployeeName, setPlan, withUserId, plan.userId])

  // Handlers
  const setOrganizationHandler = (value: string) => {
    setSelectedOrganization(value)
    let tempPlan = { ...plan }
    tempPlan.organizationId = value
    setPlan(tempPlan)
  }

  const onChangeEffectiveDate = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      const userStartDate = profile.user?.startDate ? dayjs(profile.user.startDate) : null
      if (userStartDate && newValue.isBefore(userStartDate)) {
        setEffectiveDate(userStartDate)
        let tempPlan = { ...plan }
        tempPlan.effectiveDate = userStartDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        setPlan(tempPlan)
        return
      }

      setEffectiveDate(newValue)
      let tempPlan = { ...plan }
      tempPlan.effectiveDate = newValue.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setPlan(tempPlan)
    }
  }

  const onChangeSelectedEmployee = (event: any, newValue: User | null) => {
    if (compensationTemplate) {
      setSelectedUser(newValue)

      let tempPlan = { ...plan }
      tempPlan.userId = newValue?.id || ''
      tempPlan.position = newValue?.position || ''
      setEmployeeName(newValue ? `${newValue.firstName} ${newValue.lastName}` : '')
      setPlan(tempPlan)
    }
  }

  const onChangePosition = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tempPlan = { ...plan }
    tempPlan.position = event.target.value
    setPlan(tempPlan)
  }

  // Loading State
  if (userSearchIsLoading || userIsLoading || !isDataLoaded) {
    return (
      <Grid container spacing={12}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress color="primary" />
          Loading
        </Grid>
      </Grid>
    )
  }

  // JSX Return
  return (
    <React.Fragment>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg={4}>
              <Stack spacing={1}>
                <InputLabel id="template-name-label">Template Name</InputLabel>
                <TextField
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  value={compensationTemplate?.template.label}
                  variant="standard"
                  disabled={!!plan.id}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Stack spacing={1}>
                <InputLabel id="template-name-label">Intended Role</InputLabel>
                <TextField
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  value={compensationTemplate?.template.position}
                  variant="standard"
                  disabled={!!plan.id}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Stack spacing={1} sx={{ flexGrow: 1 }}>
                <InputLabel id="employee-position-label">Position</InputLabel>
                <TextField
                  id="employee-position"
                  value={plan.position || ''}
                  onChange={onChangePosition}
                  InputProps={{
                    readOnly: mode === 'REVIEW',
                  }}
                  variant="standard"
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} lg={4}>
            <Stack spacing={1} sx={{ flexGrow: 1 }}>
              <InputLabel id="employee-select-label">Employee</InputLabel>
              {userProfile?.user && (plan.id || withUserId) ? (
                <TextField
                  key="employee-select"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  value={
                    profile.user &&
                    profile.user.firstName &&
                    profile.user.lastName &&
                    profile.user.firstName !== '' &&
                    profile.user.lastName !== ''
                      ? `${profile.user.firstName} ${profile.user.lastName}`
                      : null
                  }
                  variant="standard"
                  sx={{
                    '& .MuiInputBase-input': {
                      color: 'text.secondary',
                    },
                  }}
                />
              ) : (
                <Autocomplete
                  id="employee-select"
                  options={users}
                  autoHighlight
                  getOptionLabel={(option) => (option ? `${option.firstName} ${option.lastName}` : '')}
                  onChange={onChangeSelectedEmployee}
                  value={selectedUser}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                      variant="standard"
                    />
                  )}
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} lg={4}>
            <SingleOrganizationSelector
              key="employee-organization-select"
              userProfile={profile}
              selectedOrganizationId={selectedOrganization}
              handleChange={(value) => setOrganizationHandler(value)}
              label="Select Team"
              showLabelAbove={true}
              style={{ marginTop: 12, paddingBottom: 0 }}
              memberRolesOnly={true}
              disabled={
                !plan.userId ||
                (selectedOrganization !== '' &&
                  userProfile &&
                  userProfile.roles.filter((role) => role.role.name === 'Member').length === 1)
              }
              space={1}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Stack spacing={1} sx={{ flexGrow: 1 }}>
              <InputLabel id="employee-position-label">Effective Date</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  value={effectiveDate}
                  onChange={onChangeEffectiveDate}
                  disabled={mode === 'REVIEW'}
                  minDate={profile.user?.startDate ? dayjs(profile.user.startDate) : undefined}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                      helperText: originalEffectiveDate
                        ? `Original effective date: ${dayjs(originalEffectiveDate).format('MMM D, YYYY')}`
                        : profile.user?.startDate
                          ? `Employee start date: ${dayjs(profile.user.startDate).format('MMM D, YYYY')}`
                          : undefined,
                    },
                  }}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default EmployeeDetailForm
