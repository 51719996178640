import { useState, useEffect } from 'react'

/**
 * Retrieves the value from localStorage for a given key.
 * If the key doesn't exist or parsing fails, it returns the default value.
 *
 * @param key - The localStorage key.
 * @param defaultValue - The default value to return if the key doesn't exist.
 * @returns The parsed value from localStorage or the default value.
 */
export function getStorageValue<T>(key: string, defaultValue: T): T {
  const saved = localStorage.getItem(key)

  if (saved === null) {
    return defaultValue
  }

  try {
    return JSON.parse(saved) as T
  } catch (error) {
    console.warn(`Error parsing localStorage key "${key}":`, error)
    return defaultValue
  }
}

export function setStorageValue<T>(key: string, value: T): void {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.error(`Error setting localStorage key "${key}":`, error)
  }
}

/**
 * Hook that synchronizes state with localStorage.
 *
 * @param key - The localStorage key.
 * @param defaultValue - The default value to use if the key doesn't exist.
 * @returns A tuple containing the current value and a setter function.
 */
export function useLocalStorage<T>(key: string, defaultValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => getStorageValue<T>(key, defaultValue))

  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.error(`Error setting localStorage key "${key}":`, error)
    }
  }, [key, value])

  return [value, setValue]
}
