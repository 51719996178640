import { FormattedMessage } from 'react-intl'

import {
  AimOutlined,
  AuditOutlined,
  BarChartOutlined,
  BuildOutlined,
  CalendarOutlined,
  ClusterOutlined,
  CompassOutlined,
  ContainerOutlined,
  DashboardOutlined,
  RadarChartOutlined,
  RocketOutlined,
  SettingOutlined,
  ToolOutlined,
} from '@ant-design/icons'

import { NavItemType } from 'types/menu'
import { Permissions } from 'types/permissions'

const pages: NavItemType = {
  id: 'group-pages',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: DashboardOutlined,
    },
    {
      id: 'comp-plan',
      title: <FormattedMessage id="comp-plan" />,
      type: 'item',
      url: '/compplan',
      icon: CompassOutlined,
    },
    {
      id: 'organization',
      title: <FormattedMessage id="organization" />,
      type: 'item',
      url: '/organization',
      icon: ClusterOutlined,
    },
    {
      id: 'admin-templates',
      title: <FormattedMessage id="admin-templates" />,
      type: 'item',
      url: '/admin/templates',
      icon: BuildOutlined,
      permissions: [Permissions.TEMPLATES_WRITE],
    },
    {
      id: 'components',
      title: <FormattedMessage id="admin-comp" />,
      type: 'collapse',
      icon: RadarChartOutlined,
      permissions: [Permissions.COMPONENTS_WRITE],
      children: [
        {
          id: 'components-list',
          title: <FormattedMessage id="admin-comp-manage" />,
          type: 'item',
          url: '/admin/components/manage',
          permissions: [Permissions.TARGETS_WRITE],
          icon: RadarChartOutlined,
        },
        {
          id: 'targets',
          title: <FormattedMessage id="admin-targets" />,
          type: 'item',
          url: '/admin/components/targets',
          permissions: [Permissions.TARGETS_WRITE],
          icon: AimOutlined,
        },
        {
          id: 'metrics',
          title: <FormattedMessage id="admin-metrics" />,
          type: 'item',
          url: '/admin/components/actuals',
          permissions: [Permissions.METRICS_WRITE],
          icon: BarChartOutlined,
        },
      ],
    },
    {
      id: 'admin-approvals',
      title: <FormattedMessage id="admin-approvals" />,
      type: 'item',
      url: '/admin/approvals',
      icon: AuditOutlined,
      permissions: [Permissions.APPROVALS_WRITE],
    },
    {
      id: 'admin-accruals',
      title: <FormattedMessage id="admin-accruals" />,
      type: 'item',
      url: '/admin/accruals',
      icon: CalendarOutlined,
      permissions: [Permissions.ACCRUALS_WRITE],
    },
    {
      id: 'reporting',
      title: <FormattedMessage id="reporting" />,
      type: 'collapse',
      icon: BarChartOutlined,
      permissions: [Permissions.REPORTS_READ],
      children: [
        {
          id: 'download-forms',
          title: <FormattedMessage id="download-forms" />,
          type: 'item',
          url: '/forms/download',
          permissions: [Permissions.REPORTS_READ],
        },
        {
          id: 'upload-forms',
          title: <FormattedMessage id="upload-forms" />,
          type: 'item',
          url: '/forms/upload',
          permissions: [Permissions.REPORTS_READ],
        },
        {
          id: 'run-reports',
          title: <FormattedMessage id="run-reports" />,
          type: 'item',
          url: '/reports/run',
          permissions: [Permissions.REPORTS_READ],
        },
      ],
    },
    {
      id: 'configuration',
      title: <FormattedMessage id="configuration" />,
      type: 'collapse',
      icon: SettingOutlined,
      permissions: [Permissions.SETTINGS_WRITE],
      children: [
        {
          id: 'settings',
          title: <FormattedMessage id="settings" />,
          type: 'item',
          url: '/company-settings',
          icon: ToolOutlined,
          permissions: [Permissions.SETTINGS_WRITE],
        },
        {
          id: 'admin-ledger',
          title: <FormattedMessage id="admin-ledger" />,
          type: 'item',
          url: '/admin/ledger-accounts',
          icon: ContainerOutlined,
          permissions: [Permissions.LEDGER_ACCOUNTS_WRITE],
        },
        {
          id: 'settings-periods',
          title: <FormattedMessage id="settings-periods" />,
          type: 'item',
          url: '/period-settings',
          icon: ToolOutlined,
          permissions: [Permissions.SETTINGS_WRITE],
        },
        {
          id: 'wizard',
          title: <FormattedMessage id="wizard" />,
          type: 'item',
          url: '/wizard',
          icon: RocketOutlined,
          permissions: [Permissions.SETTINGS_WRITE],
        },
      ],
    },
  ],
}

export default pages
