import React from 'react'
import useSWR from 'swr'
import { Accrual, AccrualsQueryParams, AccrualsResponse } from 'types/accruals'
import { fetchWithToken } from './http'

export function useAccruals(token: string, params: AccrualsQueryParams, shouldFetch: boolean) {
  const { data, error, isLoading, mutate } = useSWR(
    shouldFetch
      ? [
          `accruals?organizationIds=${params.organizationIds.join(',')}&periodIds=${params.periodIds.join(',')}&status=${params.status}${
            params.searchTerm ? `&searchTerm=${params.searchTerm}` : ''
          }${params.page ? `&page=${params.page}` : ''}${params.perPage ? `&perPage=${params.perPage}` : ''}`,
          token,
        ]
      : null,
    ([url, token]) => fetchWithToken(url, token, null)
  )

  const refetch = React.useCallback(() => {
    mutate()
  }, [mutate])

  return {
    accruals: data ? (data as AccrualsResponse).accruals : [],
    pagination: data ? (data as AccrualsResponse).pagination : null,
    isLoading,
    isError: error,
    refetch,
  }
}

export function useApprovedAccruals(token: string, targetUserId: string | null) {
  const { data, error, isLoading } = useSWR(
    token ? ['accruals/approved', token, targetUserId] : null,
    ([url, token, targetUserId]) =>
      fetchWithToken(targetUserId ? `${url}?targetUserId=${targetUserId}` : url, token, null)
  )

  return {
    approvedAccruals: data as Accrual[] | undefined,
    isLoading,
    isError: error,
  }
}
