import { FormControl, InputLabel, MenuItem, Select, Stack, TextField, useTheme } from '@mui/material'
import { RichTreeView } from '@mui/x-tree-view'
import { useTreeViewApiRef } from '@mui/x-tree-view/hooks'
import { TreeViewBaseItem } from '@mui/x-tree-view/models'
import React, { FC, useState } from 'react'
import { ThemeMode } from 'types/config'
import { Organization } from 'types/organization'
import { getUniqueOrganizationRoles, UserProfile } from 'types/user-profile'
import './SingleOrganizationSelector.css'

interface SingleOrganizationSelectorProps {
  userProfile: UserProfile
  selectedOrganizationId: string
  handleChange: (selected: string) => void
  className?: string
  style?: React.CSSProperties
  width?: string | number
  label?: string
  showLabelAbove?: boolean
  disabled?: boolean
  memberRolesOnly?: boolean
  space?: number
}

const SingleOrganizationSelector: FC<SingleOrganizationSelectorProps> = ({
  userProfile,
  selectedOrganizationId,
  handleChange,
  className,
  style,
  width = '100%',
  label = 'Select Team',
  showLabelAbove = false,
  disabled = false,
  memberRolesOnly = false,
  space = 0,
}) => {
  const theme = useTheme()
  const apiRef = useTreeViewApiRef()
  const [open, setOpen] = useState(false)

  const uniqueUserRoles = getUniqueOrganizationRoles(userProfile, memberRolesOnly)

  const handleItemSelectionToggle = (event: React.SyntheticEvent, itemId: string, isSelected: boolean) => {
    if (isSelected) {
      handleChange(itemId)
      setOpen(false) // Close the dropdown after selection
    }
  }

  const renderTree = (nodes: Organization[]): TreeViewBaseItem[] => {
    const organizationMap = new Map(uniqueUserRoles.map((role) => [role.organization.id, role.organization]))

    const isTopLevelOrg = (org: Organization) => !org.parentId || !organizationMap.has(org.parentId)

    const buildTree = (org: Organization): TreeViewBaseItem => ({
      id: org.id,
      label: org.name,
      children: uniqueUserRoles
        .filter((role) => role.organization.parentId === org.id)
        .map((role) => buildTree(role.organization))
        .sort((a, b) => a.label.localeCompare(b.label)),
    })

    return nodes
      .filter(isTopLevelOrg)
      .map(buildTree)
      .sort((a, b) => a.label.localeCompare(b.label))
  }

  const rootOrganizations = uniqueUserRoles
    .map((role) => role.organization)
    .filter((org) => !org.parentId || !uniqueUserRoles.some((role) => role.organization.id === org.parentId))

  const firstRootItemId = rootOrganizations[0]?.id

  const getSelectedOrganizationName = () => {
    const selectedOrg = uniqueUserRoles.find((role) => role.organization.id === selectedOrganizationId)
    return selectedOrg ? selectedOrg.organization.name : label
  }

  const getSelectedOrganizationNameWithId = (id: string) => {
    const selectedOrg = uniqueUserRoles.find((role) => role.organization.id === id)
    return selectedOrg ? selectedOrg.organization.name : label
  }

  const hoverColor =
    theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.primary.lighter

  return disabled ? (
    <>
      <Stack spacing={space} sx={{ flexGrow: 1 }}>
        <InputLabel id="employee-org-select-label">Team</InputLabel>
        <TextField
          key="employee-organization-select"
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          value={getSelectedOrganizationNameWithId(selectedOrganizationId)}
          variant="standard"
          sx={{
            '& .MuiInputBase-input': {
              color: 'text.secondary',
            },
          }}
        />
      </Stack>
    </>
  ) : (
    <div className={className} style={{ ...style, marginBottom: 0, paddingBottom: 0, width: width }}>
      <FormControl fullWidth variant="standard">
        {showLabelAbove && <InputLabel>{label}</InputLabel>}
        <Select
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={selectedOrganizationId}
          renderValue={getSelectedOrganizationName}
          variant="standard"
          style={{ width: '100%' }}
          displayEmpty
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300,
              },
            },
          }}
          label={showLabelAbove ? undefined : label}
          disabled={disabled}
        >
          <MenuItem value={selectedOrganizationId} style={{ display: 'none' }}></MenuItem>
          <RichTreeView
            multiSelect={false}
            checkboxSelection
            style={{ width: '100%' }}
            apiRef={apiRef}
            items={renderTree(rootOrganizations)}
            selectedItems={selectedOrganizationId}
            onItemSelectionToggle={handleItemSelectionToggle}
            defaultExpandedItems={firstRootItemId ? [firstRootItemId] : []}
            sx={{
              '& .MuiTreeItem-content:hover': {
                backgroundColor: hoverColor,
              },
              '& .MuiTreeItem-content.Mui-selected:hover': {
                backgroundColor: theme.palette.action.selected,
              },
            }}
          />
        </Select>
      </FormControl>
    </div>
  )
}

export default SingleOrganizationSelector
