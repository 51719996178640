import { Box, CircularProgress } from '@mui/material'
import { FC, useState, useEffect, Suspense } from 'react'
import SimpleTitle from 'components/SimpleTitle'
import { usePeriods } from 'hooks/usePeriods'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'hooks/useProfile'
import { getRootOrganizationId } from 'types/user-profile'
import PeriodsTable from '../../../components/PeriodsTable'
import PeriodsWizard from './PeriodsWizard'
import { Period } from 'types/periods'

const ManagePeriods: FC = () => {
  const [periods, setPeriods] = useState<Period[]>([])
  const { token } = useAuth()

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ManagePeriodsContent token={token!} periods={periods} setPeriods={setPeriods} />
    </Suspense>
  )
}

const ManagePeriodsContent = ({
  token,
  periods,
  setPeriods,
}: {
  token: string
  periods: Period[]
  setPeriods: (periods: Period[]) => void
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const { profile, isLoading: isLoadingProfile } = useProfile(token!)
  const { periods: fetchedPeriods, isLoadingPeriods, handleRefetch: refetchPeriods } = usePeriods(token!, null, !!token)

  useEffect(() => {
    setIsLoading(isLoadingPeriods || isLoadingProfile)
  }, [isLoadingPeriods, isLoadingProfile])

  return (
    <div style={{ position: 'relative' }}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <div>
          <Box>
            <SimpleTitle title="Periods" />

            <Box mt={2} mb={2}>
              {isLoadingPeriods ? (
                <CircularProgress />
              ) : (
                <PeriodsWizard
                  rootOrganizationID={getRootOrganizationId(profile)!}
                  existingPeriods={fetchedPeriods?.periods}
                  refetchPeriods={refetchPeriods}
                />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              padding: 2,
              border: '1px solid #ccc',
              borderRadius: 4,
              gap: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {isLoadingPeriods ? <CircularProgress /> : <PeriodsTable periods={fetchedPeriods?.periods || []} />}
          </Box>
        </div>
      )}
    </div>
  )
}

const LoadingSpinner: FC = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
    <CircularProgress />
  </Box>
)

export default ManagePeriods
