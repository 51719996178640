import { Box, CardContent, LinearProgress, Paper, Typography, useTheme } from '@mui/material'
import React from 'react'
import { Accrual } from 'types/accruals'
import { VariableAllocationRow } from 'types/comp-allocations'
import { ExpandedCompensationPlan } from 'types/comp-plan'

interface ProgressOverviewProps {
  plan: ExpandedCompensationPlan
  slugDictionary: Record<string, string>
  calculateResult: (
    variableCompensation: number,
    row: VariableAllocationRow,
    slugDict: Record<string, string>
  ) => number
  activeRows: VariableAllocationRow[]
  approvedAccruals: Accrual[]
}

const ProgressOverview: React.FC<ProgressOverviewProps> = ({
  plan,
  slugDictionary,
  calculateResult,
  activeRows,
  approvedAccruals,
}) => {
  const theme = useTheme()

  const baseSalary = plan.plan.baseSalary
  const variableCompensation = baseSalary * plan.plan.variablePercent
  const maxVariableCompensation = variableCompensation * plan.plan.variableCap

  // Calculate total earned from approved accruals
  const totalEarned = approvedAccruals.reduce((acc, accrual) => {
    return acc + (accrual.proratedPayoutAmount || 0)
  }, 0)

  const progress = (totalEarned / maxVariableCompensation) * 100
  const formattedTotalEarned = totalEarned.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const formattedTotalPossible = maxVariableCompensation.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    <Paper sx={{ mb: 2, padding: 2 }}>
      <CardContent>
        <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 2 }}>
          Variable Compensation Progress
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Variable Compensation Earned:</Typography>
          <Typography variant="h2" sx={{ color: theme.palette.success.main, fontWeight: 'bold' }}>
            ${formattedTotalEarned}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">Maximum Variable Compensation:</Typography>
          <Typography variant="h4" sx={{ color: theme.palette.info.main, fontWeight: 'bold' }}>
            ${formattedTotalPossible}
          </Typography>
        </Box>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ mt: 3, height: 20, borderRadius: 5, backgroundColor: theme.palette.grey[300] }}
        />
      </CardContent>
    </Paper>
  )
}

export default ProgressOverview
