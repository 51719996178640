import { CheckCircleFilled, DeleteOutlined, EditOutlined, PlusOutlined, WarningFilled } from '@ant-design/icons'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import { MathJaxContext } from 'better-react-mathjax'
import AcceleratorSelector from 'components/AcceleratorSelector'
import PercentSelector from 'components/PercentSelector'
import React, { useCallback, useEffect, useState } from 'react'
import { RowType, VariableAllocationTable } from 'types/comp-allocations'
import { ExpandedTarget } from 'types/components'
import { ThemeMode } from 'types/config'
import RowManager from './RowManager'

declare global {
  interface Window {
    MathJax: {
      typesetPromise: () => Promise<void>
    }
  }
}

type AllocationTableDisplayProps = {
  allocationTables: VariableAllocationTable[]
  orderedLabels: string[]
  labelToIdMap: Record<string, string>
  editingTableIndex: number | null
  editingRowIndex: number | null
  editingLabel: string
  editingTableName: string
  handleEditClick: (tableIndex: number, rowIndex: number, label: string) => void
  handleLabelChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleLabelSave: () => void
  handleEditTableClick: (tableIndex: number, tableName: string) => void
  handleTableNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleTableNameSave: () => void
  handleKeyPress: (e: React.KeyboardEvent) => void
  handlePeriodChange: (event: SelectChangeEvent<string>, tableIndex: number, rowIndex: number) => void
  handleRestrictedVisibilityChange: (tableIndex: number, rowIndex: number, checked: boolean) => void
  handleRowTypeChange: (event: SelectChangeEvent<string>, tableIndex: number, rowIndex: number) => void
  handleEditAchievementMultiplier: (tableIndex: number, rowIndex: number, newMultiplier: string) => void
  handleUpdateAllocations: (
    tableIndex: number,
    rowIndex: number,
    allocationPercentage: number,
    minAllocationPercentage: number,
    maxAllocationPercentage: number,
    defaultPayoutPercent: number,
    minPayoutPercent: number,
    maxPayoutPercent: number
  ) => void
  handleFormulaChange: (
    tableIndex: number,
    rowIndex: number,
    newFormulaRequirements: string[],
    newFormulaOptions: string[]
  ) => void
  addRow: (tableIndex: number) => void
  removeRow: (tableIndex: number, rowIndex: number) => void
  addTable: () => void
  removeTable: (tableIndex: number) => void
  targets: ExpandedTarget[]
  organizationLabels: Record<string, string>
  periodLabels: Record<string, string>
  rootPeriod: string
  exampleBonusAmount: number
  setEditingIndices: (tableIndex: number | null, rowIndex: number | null) => void
  exampleSalary: number
  variableCompensation: number
  templateName: string // Add this new prop
  handleRowUpdate: (
    tableIndex: number,
    rowIndex: number,
    newFormulaRequirements: string[],
    newFormulaOptions: string[],
    allocationPercentage: number,
    minAllocationPercentage: number,
    maxAllocationPercentage: number,
    defaultPayoutPercent: number,
    minPayoutPercent: number,
    maxPayoutPercent: number,
    newAccelerator: string
  ) => void
  addTargetIfNotExists: (newTarget: ExpandedTarget) => void
  theme: Theme
  currency: string
  currencyDecimals: number
}

const AllocationTableDisplay: React.FC<AllocationTableDisplayProps> = ({
  allocationTables,
  orderedLabels,
  labelToIdMap,
  editingTableIndex,
  editingRowIndex,
  editingLabel,
  editingTableName,
  targets,
  organizationLabels,
  periodLabels,
  rootPeriod,
  exampleBonusAmount,
  exampleSalary,
  variableCompensation,
  handleEditClick,
  handleLabelChange,
  handleLabelSave,
  handleEditTableClick,
  handleTableNameChange,
  handleTableNameSave,
  handleKeyPress,
  handlePeriodChange,
  handleRestrictedVisibilityChange,
  handleRowTypeChange,
  handleEditAchievementMultiplier,
  handleFormulaChange,
  addRow,
  removeRow,
  removeTable,
  addTable,
  setEditingIndices,
  handleUpdateAllocations,
  templateName, // Add this new prop
  handleRowUpdate,
  addTargetIfNotExists,
  theme,
  currency,
  currencyDecimals,
}) => {
  // State for managing FormulaModal visibility and current row information
  const [isRowManagerModalOpen, setIsRowManagerModalOpen] = useState(false)
  const [selectPeriodErrorDialogOpen, setSelectPeriodErrorDialogOpen] = useState(false)
  const [currentRowManagerRow, setCurrentRowManagerRow] = useState({
    tableIndex: 0,
    rowIndex: 0,
    rowType: RowType.CALCULATION_ROW,
    periodId: '', // Added periodId to state
    label: '',
    currentRequirements: [] as string[],
    currentOptions: [] as string[],
    allocationPercentage: 0.1,
    minAllocationPercentage: 0.2,
    maxAllocationPercentage: 0.3,
    defaultPayoutPercent: 1.5,
    minPayoutPercent: 1.1,
    maxPayoutPercent: 2.0,
    achievementMultiplier: '1.00', // Add this line
  })

  // Use useCallback to memoize the handleOpenRowManagerModal function
  const handleOpenRowManagerModal = useCallback(
    (
      tableIndex: number,
      rowIndex: number,
      periodId: string,
      label: string,
      currentRequirements: string[],
      currentOptions: string[],
      allocationPercentage: number,
      minAllocationPercentage: number,
      maxAllocationPercentage: number,
      defaultPayoutPercent: number,
      minPayoutPercent: number,
      maxPayoutPercent: number,
      achievementMultiplier: string,
      rowType: RowType
    ) => {
      setCurrentRowManagerRow({
        tableIndex,
        rowIndex,
        periodId,
        label,
        currentRequirements,
        currentOptions,
        allocationPercentage,
        minAllocationPercentage,
        maxAllocationPercentage,
        defaultPayoutPercent,
        minPayoutPercent,
        maxPayoutPercent,
        achievementMultiplier,
        rowType,
      })
      setIsRowManagerModalOpen(true)
    },
    []
  )

  // Function to handle FormulaModal close
  const handleCloseRowManagerModal = () => {
    setIsRowManagerModalOpen(false)
  }

  const config = {
    loader: { load: ['input/tex', 'output/svg'] },
    tex: {
      inlineMath: [
        ['$', '$'],
        ['\\(', '\\)'],
      ],
    },
  }

  useEffect(() => {
    const checkMathJaxReady = () => {
      if (window.MathJax && typeof window.MathJax.typesetPromise === 'function') {
        window.MathJax.typesetPromise().catch((err) => {
          //console.error('MathJax typesetPromise failed:', err)
        })
      } else {
        //console.error('MathJax or typesetPromise not available')
      }
    }

    checkMathJaxReady()
  }, [allocationTables])

  const handleAddRow = (tableIndex: number) => {
    addRow(tableIndex)
    const newRowIdx = allocationTables[tableIndex].rows.length // New row index
    setEditingIndices(tableIndex, newRowIdx) // Set editing indices to the new row
  }

  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    type: 'table' | 'row'
    index: number
    rowIndex?: number
  } | null>(null)

  const handleDeleteConfirmation = (type: 'table' | 'row', index: number, rowIndex?: number) => {
    setDeleteConfirmation({ type, index, rowIndex })
  }

  const handleConfirmDelete = () => {
    if (deleteConfirmation) {
      if (deleteConfirmation.type === 'table') {
        removeTable(deleteConfirmation.index)
      } else if (deleteConfirmation.type === 'row' && deleteConfirmation.rowIndex !== undefined) {
        removeRow(deleteConfirmation.index, deleteConfirmation.rowIndex)
      }
    }
    setDeleteConfirmation(null)
  }

  const handleCancelDelete = () => {
    setDeleteConfirmation(null)
  }

  const handlePeriodSelectErrorDialogClose = () => {
    setSelectPeriodErrorDialogOpen(false)
  }

  // Add this function to calculate the total allocation percentage for a table
  const calculateTotalAllocation = (rows: any[]) => {
    return Math.round(rows.reduce((total, row) => total + (row.allocationPercentage || 0), 0) * 100)
  }

  // Function to calculate total allocation across all tables
  const calculateTotalAllocationAcrossTables = (tables: VariableAllocationTable[]) => {
    return Math.round(
      tables.reduce(
        (total, table) =>
          total + table.rows.reduce((tableTotal, row) => tableTotal + (row.allocationPercentage || 0), 0),
        0
      ) * 100
    )
  }

  // Add this function to check if all rows are valid
  const areAllRowsValid = (tables: VariableAllocationTable[]) => {
    return tables.every((table) => table.rows.every((row) => row.formulaOptions && row.formulaOptions.length > 0))
  }

  const totalAllocation = calculateTotalAllocationAcrossTables(allocationTables)
  const allRowsValid = areAllRowsValid(allocationTables)

  const hoverColor =
    theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.primary.lighter

  console.log(allocationTables)

  return (
    <MathJaxContext config={config}>
      <Grid container spacing={3}>
        <Grid item xs={12} container alignItems="center">
          <Typography variant="h3" mr={4}>
            {`${templateName} - Template Allocations`}
          </Typography>
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ marginLeft: '16px', display: 'flex', alignItems: 'center' }}
          >
            Total: {totalAllocation}%
            {totalAllocation === 100 ? (
              <CheckCircleFilled style={{ color: theme.palette.success.main, marginLeft: '8px' }} />
            ) : (
              <WarningFilled style={{ color: theme.palette.warning.main, marginLeft: '8px' }} />
            )}
          </Typography>
          <Typography
            variant="h6"
            color={allRowsValid ? 'textSecondary' : 'error'}
            style={{ marginLeft: '16px', display: 'flex', alignItems: 'center' }}
          >
            {allRowsValid ? 'All Rows Valid' : 'Invalid Rows Present'}
            {allRowsValid ? (
              <CheckCircleFilled style={{ color: theme.palette.success.main, marginLeft: '8px' }} />
            ) : (
              <WarningFilled style={{ color: theme.palette.warning.main, marginLeft: '8px' }} />
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {allocationTables.map((table, tableIndex) => (
            <div style={{ marginBottom: '20px' }} key={tableIndex}>
              <Paper sx={{ padding: '20px' }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    '& .MuiTableCell-sizeSmall': {
                      padding: '4px 8px',
                      fontSize: '0.75rem',
                    },
                    '& .MuiTableHead-root': {
                      backgroundColor: theme.palette.background.paper,
                    },
                  }}
                >
                  <Grid item xs={12} container alignItems="center">
                    <Grid item xs={2}>
                      {editingTableIndex === tableIndex && editingRowIndex === null ? (
                        <TextField
                          value={editingTableName}
                          onChange={handleTableNameChange}
                          onBlur={handleTableNameSave}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleTableNameSave()
                              e.preventDefault()
                            }
                          }}
                          fullWidth
                          variant="standard"
                          autoFocus
                          InputProps={{
                            onFocus: (event) => event.target.select(),
                          }}
                        />
                      ) : (
                        <>
                          <Grid container alignItems="center">
                            <Grid item>
                              <Typography variant="h4">{table.label}</Typography>
                            </Grid>
                            <Grid item>
                              <IconButton size="small" onClick={() => handleEditTableClick(tableIndex, table.label)}>
                                <EditOutlined />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'center' }}>
                      <Typography variant="h5">Type</Typography>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: 'center' }}>
                      <Typography variant="h5">Period</Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'center' }}>
                      <Typography variant="h5">
                        Allocation %{' '}
                        <Typography
                          component="span"
                          variant="caption"
                          color="textSecondary"
                          style={{ fontSize: '0.8em' }}
                        >
                          ({calculateTotalAllocation(table.rows)}%)
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'center' }}>
                      <Typography variant="h5">Max Payout %</Typography>
                    </Grid>
                    <Grid item xs={1} style={{ textAlign: 'center' }}>
                      <Typography variant="h5">Accelerator</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <Typography variant="h5">Actions</Typography>
                      <IconButton size="small" onClick={() => handleDeleteConfirmation('table', tableIndex)}>
                        <DeleteOutlined />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {table.rows.map((row, rowIndex) => (
                    <Grid
                      container
                      item
                      xs={12}
                      key={rowIndex}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'transparent !important',
                        },
                      }}
                      alignItems="center"
                    >
                      <Grid item xs={2} pr={4}>
                        {editingTableIndex === tableIndex && editingRowIndex === rowIndex ? (
                          <TextField
                            placeholder="Enter Target Line Item Name"
                            value={editingLabel}
                            onChange={handleLabelChange}
                            onKeyPress={handleKeyPress}
                            fullWidth
                            variant="standard"
                            autoFocus
                            InputProps={{
                              onFocus: (event) => event.target.select(),
                            }}
                          />
                        ) : (
                          <>
                            {row.label}
                            <IconButton size="small" onClick={() => handleEditClick(tableIndex, rowIndex, row.label)}>
                              <EditOutlined />
                            </IconButton>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: 'center', paddingBottom: '8px', paddingRight: '8px' }}>
                        <Select
                          value={row.rowType || RowType.CALCULATION_ROW}
                          onChange={(e) => handleRowTypeChange(e, tableIndex, rowIndex)}
                          displayEmpty
                          variant="standard"
                          fullWidth
                        >
                          <MenuItem key="display-option" value={RowType.DISPLAY_ROW}>
                            Display Only
                          </MenuItem>
                          <MenuItem key="achievement-option" value={RowType.CALCULATION_ROW}>
                            Variable
                          </MenuItem>
                          <MenuItem key="fixed-value-option" value={RowType.FIXED_VALUE_ROW}>
                            Fixed Value
                          </MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={1} style={{ textAlign: 'center', paddingBottom: '8px', paddingLeft: '8px' }}>
                        <Select
                          value={orderedLabels.find((label) => labelToIdMap[label] === row.periodId) || ''}
                          onChange={(e) => handlePeriodChange(e, tableIndex, rowIndex)}
                          displayEmpty
                          fullWidth
                          variant="standard"
                        >
                          <MenuItem value="" disabled>
                            Select Period
                          </MenuItem>
                          {orderedLabels.map((label) => (
                            <MenuItem key={labelToIdMap[label]} value={label}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <PercentSelector
                            value={row.allocationPercentage || 0}
                            setValue={(newValue) =>
                              handleUpdateAllocations(
                                tableIndex,
                                rowIndex,
                                Math.min(
                                  Math.max(
                                    typeof newValue === 'function' ? newValue(row.allocationPercentage || 0) : newValue,
                                    row.minAllocationPercentage ?? 0
                                  ),
                                  row.maxAllocationPercentage ?? 1
                                ),
                                row.formulaOptions.length == 0 ? 0 : row.minAllocationPercentage ?? 0,
                                row.formulaOptions.length == 0 ? 1 : row.maxAllocationPercentage ?? 1,
                                row.defaultPayout ?? 0,
                                row.formulaOptions.length == 0 ? 0 : row.minPayout ?? 0,
                                row.formulaOptions.length == 0 ? 2 : row.maxPayout ?? 0
                              )
                            }
                            incrementAmount={0.01}
                            min={row.formulaOptions.length == 0 ? 0 : row.minAllocationPercentage!}
                            max={row.formulaOptions.length == 0 ? 1 : row.maxAllocationPercentage!}
                            disabled={row.formulaOptions.length === 0 || row.rowType == RowType.DISPLAY_ROW}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <PercentSelector
                            value={row.defaultPayout || 0}
                            setValue={(newValue) =>
                              handleUpdateAllocations(
                                tableIndex,
                                rowIndex,
                                row.allocationPercentage ?? 0,
                                row.formulaOptions.length == 0 ? 0 : row.minAllocationPercentage!,
                                row.formulaOptions.length == 0 ? 1 : row.maxAllocationPercentage!,
                                Math.min(
                                  Math.max(
                                    typeof newValue === 'function' ? newValue(row.defaultPayout || 0) : newValue,
                                    row.minPayout ?? 0
                                  ),
                                  row.maxPayout ?? 2
                                ),
                                row.minPayout ?? 0,
                                row.maxPayout ?? 2
                              )
                            }
                            incrementAmount={0.01}
                            min={row.minPayout ?? 0}
                            max={row.maxPayout ?? 2}
                            disabled={row.formulaOptions.length === 0 || row.rowType == RowType.DISPLAY_ROW}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={1} style={{ textAlign: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <AcceleratorSelector
                            value={row.achievementMultiplier}
                            setValue={(newValue) => handleEditAchievementMultiplier(tableIndex, rowIndex, newValue)}
                            incrementAmount={0.1}
                            fontSize="0.9rem"
                            disabled={row.formulaOptions.length === 0 || row.rowType !== RowType.CALCULATION_ROW}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          {row.formulaOptions && row.formulaOptions.length > 0 ? (
                            <CheckCircleFilled style={{ color: theme.palette.success.main, marginRight: '8px' }} />
                          ) : (
                            <WarningFilled style={{ color: theme.palette.warning.main, marginRight: '8px' }} />
                          )}
                          <IconButton
                            size="small"
                            onClick={() => {
                              if (row.periodId !== '')
                                handleOpenRowManagerModal(
                                  tableIndex,
                                  rowIndex,
                                  row.periodId || '',
                                  row.label || '',
                                  row.formulaRequirements || [],
                                  row.formulaOptions || [],
                                  row.allocationPercentage || 0.2,
                                  row.minAllocationPercentage || 0.01,
                                  row.maxAllocationPercentage || 0.3,
                                  row.defaultPayout || 1.5,
                                  row.minPayout || 1.1,
                                  row.maxPayout || 2.0,
                                  row.achievementMultiplier || '1.00',
                                  row.rowType
                                )
                              else {
                                setSelectPeriodErrorDialogOpen(true)
                              }
                            }}
                          >
                            <EditOutlined />
                          </IconButton>
                          {/* Move row trashcan here */}
                          <IconButton
                            size="small"
                            onClick={() => handleDeleteConfirmation('row', tableIndex, rowIndex)}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        </div>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      '&:hover': {
                        backgroundColor: 'transparent !important',
                      },
                    }}
                  >
                    <Button
                      onClick={() => handleAddRow(tableIndex)}
                      sx={{
                        '&:hover': {
                          backgroundColor: hoverColor,
                        },
                      }}
                    >
                      Add Row
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Tooltip title="Add Section to Template">
            <Button variant="text" startIcon={<PlusOutlined />} onClick={addTable}>
              Section
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      {isRowManagerModalOpen && (
        <RowManager
          key={`${currentRowManagerRow.tableIndex}-${currentRowManagerRow.rowIndex}`}
          isOpen={isRowManagerModalOpen}
          handleClose={handleCloseRowManagerModal}
          handleFormulaChange={handleFormulaChange}
          tableIndex={currentRowManagerRow.tableIndex}
          rowIndex={currentRowManagerRow.rowIndex}
          rowLabel={currentRowManagerRow.label}
          targets={targets}
          organizationLabels={organizationLabels}
          periodId={currentRowManagerRow.periodId}
          periodLabel={periodLabels[currentRowManagerRow.periodId]}
          exampleSalary={exampleSalary}
          variableCompensation={variableCompensation}
          currentRequirements={currentRowManagerRow.currentRequirements}
          currentOptions={currentRowManagerRow.currentOptions}
          allocationPercentage={currentRowManagerRow.allocationPercentage}
          minAllocationPercentage={currentRowManagerRow.minAllocationPercentage}
          maxAllocationPercentage={currentRowManagerRow.maxAllocationPercentage}
          defaultPayoutPercent={currentRowManagerRow.defaultPayoutPercent}
          minPayoutPercent={currentRowManagerRow.minPayoutPercent}
          maxPayoutPercent={currentRowManagerRow.maxPayoutPercent}
          handleUpdateAllocations={handleUpdateAllocations}
          accelerator={currentRowManagerRow.achievementMultiplier || '1.00'}
          handleUpdateAccelerator={handleEditAchievementMultiplier}
          handleRowUpdate={handleRowUpdate}
          addTargetIfNotExists={addTargetIfNotExists}
          theme={theme}
          currency={currency}
          currencyDecimals={currencyDecimals}
          rowType={currentRowManagerRow.rowType}
        />
      )}

      <Dialog open={deleteConfirmation !== null} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this {deleteConfirmation?.type}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={selectPeriodErrorDialogOpen}
        onClose={handlePeriodSelectErrorDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={{ p: 1, py: 1.5 }}>
          <DialogTitle id="alert-dialog-title">Error</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please select a period to edit this row.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handlePeriodSelectErrorDialogClose} autoFocus>
              Okay
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </MathJaxContext>
  )
}

export default AllocationTableDisplay
