import { SearchOutlined } from '@ant-design/icons'
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import CircularLoader from 'components/CircularLoader'
import ComponentDisplay from 'components/ComponentDisplay'
import ComponentField from 'components/ComponentField'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import { useSearch } from 'contexts/SearchContext'
import useAuth from 'hooks/useAuth'
import { useSearchExpandedTargets } from 'hooks/useComponents'
import { useProfile } from 'hooks/useProfile'
import { FC, useEffect, useState } from 'react'
import { ExpandedTarget } from 'types/components'
import { ThemeMode } from 'types/config'
import AllocationAdjuster from './AlocationAdjuster'
import { RowType } from 'types/comp-allocations'

const steps = ['Select a Component', 'Set a Hurdle', 'Adjust Allocation']

type SimpleRowEditorProps = {
  organizationLabels: Record<string, string>
  periodId: string
  periodLabel: string
  exampleSalary: number
  variableCompensation: number
  acceleratorInitial: string
  handleUpdateAccelerator: (tableIndex: number, rowIndex: number, accelerator: string) => void
  handleCloseModal: () => void
  handleFormulaChange: (requirements: string[], options: string[]) => void
  handleUpdateAllocations: (
    allocationPercentage: number,
    minAllocationPercentage: number,
    maxAllocationPercentage: number,
    defaultPayoutPercent: number,
    minPayoutPercent: number,
    maxPayoutPercent: number
  ) => void
  tableIndex: number
  rowIndex: number
  handleRowUpdate: (
    tableIndex: number,
    rowIndex: number,
    newFormulaRequirements: string[],
    newFormulaOptions: string[],
    allocationPercentage: number,
    minAllocationPercentage: number,
    maxAllocationPercentage: number,
    defaultPayoutPercent: number,
    minPayoutPercent: number,
    maxPayoutPercent: number,
    newAccelerator: string
  ) => void
  addTargetIfNotExists: (newTarget: ExpandedTarget) => void
  theme: Theme
  currency: string
  currencyDecimals: number
  rowType: RowType
}

const SimpleRowEditor: FC<SimpleRowEditorProps> = ({
  organizationLabels,
  periodId,
  periodLabel,
  exampleSalary,
  variableCompensation,
  acceleratorInitial,
  handleUpdateAccelerator,
  handleCloseModal,
  handleFormulaChange,
  handleUpdateAllocations,
  tableIndex,
  rowIndex,
  handleRowUpdate,
  addTargetIfNotExists,
  theme,
  currency,
  currencyDecimals,
  rowType,
}) => {
  const [activeStep, setActiveStep] = useState(0)
  const { search, setSearch } = useSearch()
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const { token } = useAuth()
  const { profile, isLoading: isLoadingProfile } = useProfile(token!)
  const { targets, isLoading, isError } = useSearchExpandedTargets(
    token!,
    {
      searchTerm: search,
      organizationIds: selectedOrganizations,
      periodId: [periodId],
      userIds: ['00000000-0000-0000-0000-000000000000'],
      perPage: 100,
    },
    true
  )
  const [selectedTarget, setSelectedTarget] = useState<ExpandedTarget | null>(null)
  const [hurdleValue, setHurdleValue] = useState('')
  const [allocationValues, setAllocationValues] = useState([0.1, 0.2, 0.3])
  const [maxPayout, setMaxPayout] = useState([1.1, 1.5, 1.9])
  const [comparisonOperator, setComparisonOperator] = useState('>=')
  const [accelerator, setAccelerator] = useState(acceleratorInitial)

  const isNextDisabled = activeStep === 0 && !selectedTarget
  const isFinishEnabled = activeStep === steps.length - 1

  const hoverColor = theme.palette.mode == ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.primary.lighter

  const handleFinish = () => {
    // Set formula requirements and options
    let requirements: string[] = []
    let options: string[] = []

    if (selectedTarget) {
      options = [`${selectedTarget.component.slug}/${selectedTarget.target.slug}`]

      if (hurdleValue) {
        requirements = [`${selectedTarget.component.slug} ${comparisonOperator} ${hurdleValue}`]
      }
    }

    handleFormulaChange(requirements, options)

    // Sort allocation values and max payout values
    const sortedAllocationValues = [...allocationValues].sort((a, b) => a - b)
    const sortedMaxPayout = [...maxPayout].sort((a, b) => a - b)

    const minAllocationPercentage = sortedAllocationValues[0]
    const allocationPercentage = sortedAllocationValues[1]
    const maxAllocationPercentage = sortedAllocationValues[2]
    const minPayoutPercent = sortedMaxPayout[0]
    const defaultPayoutPercent = sortedMaxPayout[1]
    const maxPayoutPercent = sortedMaxPayout[2]

    handleUpdateAllocations(
      allocationPercentage,
      minAllocationPercentage,
      maxAllocationPercentage,
      defaultPayoutPercent,
      minPayoutPercent,
      maxPayoutPercent
    )

    console.log('accelerator', accelerator)
    handleUpdateAccelerator(tableIndex, rowIndex, accelerator)

    handleRowUpdate(
      tableIndex,
      rowIndex,
      requirements,
      options,
      allocationPercentage,
      minAllocationPercentage,
      maxAllocationPercentage,
      defaultPayoutPercent,
      minPayoutPercent,
      maxPayoutPercent,
      accelerator
    )

    handleCloseModal()
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    console.log('search', search)
  }, [search])

  const handleNext = () => {
    if (activeStep === 1 && selectedTarget && hurdleValue) {
      const requirement = `${selectedTarget.component.slug} ${comparisonOperator} ${hurdleValue}`
      const option = `${selectedTarget.component.slug}/${selectedTarget.target.slug}`
      handleFormulaChange([requirement], [option])
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleOrganizationChange = (newOrganizations: string[]) => {
    setSelectedOrganizations(newOrganizations)
  }

  const handleSelectTarget = (target: ExpandedTarget) => {
    setSelectedTarget(target)
    addTargetIfNotExists(target)
    setHurdleValue('')
    setAllocationValues([0.1, 0.2, 0.3])
    setMaxPayout([1.1, 1.5, 1.9])
    handleNext()
  }

  const handleHurdleChange = (value: string) => {
    console.log('value', value)
    setHurdleValue(value)
  }

  const handleOperatorChange = (event: SelectChangeEvent) => {
    setComparisonOperator(event.target.value)
  }

  function getTargetLabel(target: ExpandedTarget) {
    const targetLabel = target.target.label
    const orgLabel = organizationLabels[target.target.organizationId!]
    const targetWithOrgRemoved = targetLabel.replace(orgLabel, '')
    return `${orgLabel} ${targetWithOrgRemoved}`
  }

  const selectComponentStep = () => {
    const targetSection =
      isLoading || isError ? (
        <CircularLoader />
      ) : (
        <Box
          sx={{
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '4px',
            p: 2,
          }}
        >
          {targets.targets.map((target, index) => (
            <Box
              key={index}
              p={1}
              sx={{
                '&:hover': {
                  backgroundColor: hoverColor,
                  transition: 'background-color 0.3s',
                },
              }}
            >
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{getTargetLabel(target)}</Box>
                <Button variant="contained" color="primary" onClick={() => handleSelectTarget(target)}>
                  Select
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      )

    return (
      <Box overflow="hidden" mb={2}>
        <Box display="flex" p={2} justifyContent="space-between">
          <Box width="50%" pr={1} mb={0} alignContent="center">
            <FormControl variant="standard" fullWidth>
              <TextField
                placeholder="Enter Search Term"
                label="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  userSelect: 'none',
                  width: '100%',
                  '& .MuiInputLabel-root': {
                    backgroundColor: 'transparent',
                  },
                }}
                variant="standard"
                onChange={handleSearchChange}
                autoFocus
              />
            </FormControl>
          </Box>
          <Box width="50%" pl={1} alignContent="center">
            <MultiOrganizationSelector
              selectedOrganizationIds={selectedOrganizations}
              handleChange={handleOrganizationChange}
              userProfile={profile}
            />
          </Box>
        </Box>
        <Box p={2} overflow="auto">
          {targetSection}
        </Box>
      </Box>
    )
  }

  const setHurdleStep = () => {
    if (!selectedTarget) {
      return <div>No target selected</div>
    }

    const { target, component } = selectedTarget
    const componentLabel = component.label.trimEnd()
    // const targetValue = formatValue(Number(target.value), selectedTarget.component.componentType)

    return (
      <Box p={2} m={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
        <Box p={2} m={2} display="flex" flexDirection="column">
          <Box mb={8}>
            <Typography component="span" fontWeight="bold" color="primary">
              {componentLabel}
            </Typography>{' '}
            has a target of{' '}
            <ComponentDisplay
              value={target.value}
              componentType={component.componentType}
              currency={currency}
              currencyDecimals={currencyDecimals}
              fontWeight="bold"
              color="primary"
            />{' '}
            in{' '}
            <Typography component="span" fontWeight="bold" color="primary">
              {periodLabel}
            </Typography>
            .
          </Box>
          <Typography variant="h5" gutterBottom>
            Set an achievement hurdle for {componentLabel}:
          </Typography>
          <Box display="flex" alignItems="center" width="100%">
            <FormControl variant="standard">
              <InputLabel>Comparison Operator</InputLabel>
              <Select
                value={comparisonOperator}
                onChange={handleOperatorChange}
                variant="standard"
                sx={{ minWidth: 200, mr: 4 }}
              >
                <MenuItem value=">">Greater Than</MenuItem>
                <MenuItem value=">=">Greater Than or Equal</MenuItem>
                <MenuItem value="<">Less Than</MenuItem>
                <MenuItem value="<=">Less Than or Equal</MenuItem>
                <MenuItem value="=">Equal To</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ width: '200px' }}>
              <FormControl variant="standard">
                <ComponentField
                  value={hurdleValue}
                  onChange={handleHurdleChange}
                  componentType={component.componentType}
                  name=""
                  label="Hurdle Value"
                  placeholder="Enter a value"
                  currency={currency}
                  currencyDecimals={currencyDecimals}
                />
              </FormControl>
            </Box>
          </Box>
          <Typography variant="body2" color="textSecondary" mt={1}>
            You can leave it blank for no hurdle.
          </Typography>
        </Box>
      </Box>
    )
  }

  const adjustAllocationsStep = () => (
    <AllocationAdjuster
      allocationValues={allocationValues}
      setAllocationValues={setAllocationValues}
      maxPayout={maxPayout}
      setMaxPayout={setMaxPayout}
      exampleSalary={exampleSalary}
      variableCompensation={variableCompensation}
      accelerator={accelerator}
      setAccelerator={setAccelerator}
      theme={theme}
      currency={currency}
      currencyDecimals={currencyDecimals}
      rowType={rowType}
    />
  )

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <div>{selectComponentStep()}</div>
      case 1:
        return <div>{setHurdleStep()}</div>
      case 2:
        return adjustAllocationsStep()
      default:
        return <div>Unknown step</div>
    }
  }

  console.log(activeStep)

  if (isLoadingProfile) return <div>Loading profile...</div>

  return (
    <Box id="simple-row-editor-box" display="flex" flexDirection="column" height="100%">
      <Stepper activeStep={activeStep} orientation="horizontal">
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box mt={2} flexGrow={1} height="100%" overflow="auto">
        {renderStepContent(activeStep)}
      </Box>
      <Box mt={2} display="flex" justifyContent="space-between">
        <Button onClick={handleCloseModal}>Cancel</Button>
        <Box>
          <Button sx={{ mr: 3 }} disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={isFinishEnabled ? handleFinish : handleNext}
            disabled={isNextDisabled}
          >
            {isFinishEnabled ? 'Finish' : 'Next'}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default SimpleRowEditor
