import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material'
import React from 'react'
import { ThemeMode } from 'types/config'

interface TrueFalseSelectorProps {
  label?: string
  value?: boolean
  onChange: (value: boolean) => void
  name: string
  style?: React.CSSProperties // Add the optional style property
}

const TrueFalseSelector: React.FC<TrueFalseSelectorProps> = ({ label, value = true, onChange, name, style }) => {
  const theme = useTheme()

  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value === 'true')
  }

  const hoverBackgroundColor =
    theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.secondary.dark

  return (
    <FormControl fullWidth variant="standard" style={style}>
      {' '}
      {/* Pass the style prop */}
      <InputLabel>{label}</InputLabel>
      <Select
        value={value.toString()}
        onChange={handleChange}
        name={name}
        MenuProps={{
          PaperProps: {
            sx: {
              '& .MuiMenuItem-root:hover': {
                backgroundColor: hoverBackgroundColor,
              },
            },
          },
        }}
      >
        <MenuItem value="true">True</MenuItem>
        <MenuItem value="false">False</MenuItem>
      </Select>
    </FormControl>
  )
}

export default TrueFalseSelector
