import { Box, Divider, Grid, Paper, Theme, Typography } from '@mui/material'
import { isWithinInterval } from 'date-fns'
import React from 'react'
import { Accrual } from 'types/accruals'
import { VariableAllocationRow } from 'types/comp-allocations'
import { ExpandedCompensationPlan } from 'types/comp-plan'
import { Target } from 'types/components'
import { Period, parsePeriodDate } from 'types/periods'
import TargetRow from './TargetRow'

interface PeriodDetailsProps {
  periodLabel: string
  targets: Target[]
  findMetricValue: (targetComponentId: string, targetPeriodId: string) => number
  calculateProgress: (targetComponentId: string, targetValue: string, targetPeriodId: string) => number
  periodLabelMap: Record<string, string>
  periodIdMap: Record<string, Period>
  plan: ExpandedCompensationPlan
  slugDictionary: Record<string, string>
  calculateResult: (
    variableCompensation: number,
    row: VariableAllocationRow,
    slugDict: Record<string, string>
  ) => number
  activeRows: VariableAllocationRow[]
  accruals: Accrual[]
  theme: Theme
}

const PeriodDetails: React.FC<PeriodDetailsProps> = ({
  periodLabel,
  targets,
  findMetricValue,
  calculateProgress,
  periodLabelMap,
  periodIdMap,
  plan,
  slugDictionary,
  calculateResult,
  activeRows,
  accruals,
  theme,
}) => {
  console.log('periodLabel:', periodLabel)
  console.log('periodLabelMap:', periodLabelMap)

  const selectedPeriodLabel =
    Object.keys(periodLabelMap).find((key) => {
      console.log('Checking key:', key)
      const includes = key.replaceAll(' ', '').startsWith(periodLabel.replaceAll(' ', ''))
      console.log(`Does ${key} include ${periodLabel}?`, includes)
      return includes
    }) || ''

  const selectedPeriodId = periodLabelMap[selectedPeriodLabel]

  console.log('selectedPeriodId:', selectedPeriodId)
  const selectedPeriod = periodIdMap[selectedPeriodId]

  console.log('selectedPeriod:', selectedPeriod)

  // Add null checks for selectedPeriod
  const selectedPeriodStartDate = selectedPeriod ? parsePeriodDate(selectedPeriod.startDate) : null
  const selectedPeriodEndDate = selectedPeriod ? parsePeriodDate(selectedPeriod.endDate) : null

  // const variableCompensation = plan.plan.baseSalary * plan.plan.variablePercent

  const isWithinSelectedPeriod = (startDate: string, endDate: string) => {
    if (!selectedPeriodStartDate || !selectedPeriodEndDate) return false
    const parsedStartDate = parsePeriodDate(startDate)
    const parsedEndDate = parsePeriodDate(endDate)

    console.log('parsedStartDate', parsedStartDate)
    console.log('selectedPeriodStartDate', selectedPeriodStartDate)
    console.log('parsedEndDate', parsedEndDate)
    console.log('selectedPeriodEndDate', selectedPeriodEndDate)

    return (
      isWithinInterval(parsedStartDate, {
        start: selectedPeriodStartDate,
        end: selectedPeriodEndDate,
      }) &&
      isWithinInterval(parsedEndDate, {
        start: selectedPeriodStartDate,
        end: selectedPeriodEndDate,
      })
    )
  }

  // const filteredActiveRows = activeRows.filter((row) => {
  //   if (!row.periodId) return false
  //   const rowPeriod = periodIdMap[row.periodId]
  //   if (!rowPeriod) return false
  //   return isWithinSelectedPeriod(rowPeriod.startDate, rowPeriod.endDate)
  // })

  const filteredAccruals = accruals.filter((accrual) => {
    console.log('accrual', accrual)
    console.log('periodId', accrual.periodId)
    console.log('periodIdMap', periodIdMap)

    const accrualPeriod = periodIdMap[accrual.periodId]
    console.log('accrualPeriod **', accrualPeriod)
    if (!accrualPeriod) return false
    console.log('isWithinSelectedPeriod', isWithinSelectedPeriod(accrualPeriod.startDate, accrualPeriod.endDate))
    return isWithinSelectedPeriod(accrualPeriod.startDate, accrualPeriod.endDate)
  })

  const totalCompensation = filteredAccruals.reduce((total, accrual) => {
    return total + (accrual.proratedPayoutAmount || 0)
  }, 0)

  const componentFormatMap = plan.components.reduce(
    (acc: Record<string, string>, component) => {
      acc[component.id!] = component.componentType
      return acc
    },
    {} as Record<string, string>
  )

  return (
    <Box display="flex" alignItems="stretch" key="period-details-box">
      <Grid container spacing={2} sx={{ flex: 1 }} key="period-details-grid">
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }} key="targets-grid-item">
          <Paper sx={{ mb: 2, p: 4, flex: 1 }} key="targets-paper">
            <div style={{ marginBottom: '16px' }} key="targets-header">
              <Typography variant="h4" key="targets-title">
                {periodLabel} Targets
              </Typography>
            </div>
            {targets
              .filter((target) => {
                if (!target.periodId) return false
                const targetPeriod = periodIdMap[target.periodId]
                if (!targetPeriod) return false
                return isWithinSelectedPeriod(targetPeriod.startDate, targetPeriod.endDate)
              })
              .map((target, index) => {
                const componentType = componentFormatMap[target.componentId]
                const metricValue =
                  target.componentId && target.periodId ? findMetricValue(target.componentId, target.periodId) || 0 : 0
                const progress =
                  metricValue === 0 || !target.value
                    ? 0
                    : calculateProgress(target.componentId, target.value.toString(), target.periodId!)
                return (
                  <TargetRow
                    key={`target-row-${target.componentId || index}-${target.periodId}-${target.userId || index}`}
                    target={target}
                    findMetricValue={findMetricValue}
                    calculateProgress={() => progress}
                    theme={theme}
                    componentType={componentType}
                  />
                )
              })}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }} key="compensation-grid-item">
          <Paper sx={{ mb: 2, p: 4, flex: 1 }} key="compensation-paper">
            <div style={{ marginBottom: '16px' }} key="compensation-header">
              <Typography variant="h4" key="compensation-title">
                {periodLabel} Variable Compensation Earnings
              </Typography>
            </div>
            {filteredAccruals.map((accrual, index) => {
              const formattedCompensation = (accrual.proratedPayoutAmount || 0)
                .toFixed(0)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

              const prorationText =
                accrual.proratedDays > 0 && accrual.proratedDays < accrual.totalPeriodDays
                  ? `(prorated for ${accrual.proratedDays} days)`
                  : ''

              return (
                <div
                  key={`compensation-row-${accrual.id || index}`}
                  style={{ display: 'grid', gridTemplateColumns: '1fr auto auto', gap: '16px', marginBottom: '8px' }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontSize: '1rem', color: theme.palette.text.primary }}
                    key={`row-label-${accrual.id || index}`}
                  >
                    {accrual.allocationTableName} | {accrual.allocationRowName}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: '1rem', color: theme.palette.text.secondary }}
                    key={`row-proration-${accrual.id || index}`}
                  >
                    {prorationText}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: '1rem', color: theme.palette.text.primary, textAlign: 'right' }}
                    key={`row-value-${accrual.id || index}`}
                  >
                    ${formattedCompensation}
                  </Typography>
                </div>
              )
            })}
            <Divider sx={{ my: 2 }} key="compensation-divider" />
            <div
              style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}
              key="total-compensation"
            >
              <Typography variant="h5" key="total-label">
                Total
              </Typography>
              <Typography variant="h5" key="total-value">
                ${totalCompensation.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PeriodDetails
