import {
  AuditOutlined,
  CloseCircleOutlined,
  EditOutlined,
  EyeOutlined,
  FormOutlined,
  PlusOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import AnimateButton from 'components/@extended/AnimateButton'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import SearchInput from 'components/SearchInput'
import SimpleTitle from 'components/SimpleTitle'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'hooks/useProfile'
import { useSearchUsersWithPlanInfo } from 'hooks/useUsers'
import * as React from 'react'
import { startTransition, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Permissions } from 'types/permissions'
import { KeyedObject } from 'types/root'
import { UserWithPlanInfo } from 'types/user'
import { getOrganizationIdsWithPermissions, getOrganizationLabelMap } from 'types/user-profile'
import { useLocalStorage } from 'utils/useLocalStorage'

const Organization: React.FC = () => {
  const [search, setSearch] = useState('')
  const theme = useTheme()
  const [page, setPage] = useLocalStorage<number>('team-page', 0)
  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>('team-rows-per-page', 100)
  const [selectedOrganizations, setSelectedOrganizations] = useLocalStorage<string[]>('team-selected-organizations', [])
  const [planFilters, setPlanFilters] = useLocalStorage<string[]>('team-plan-filters', [
    'withoutPlans',
    'unapproved',
    'approved',
  ])

  const { token } = useAuth()
  const { profile } = useProfile(token!)
  const {
    users = [],
    isLoading,
    pagination = { totalItems: 0, totalPages: 1 },
  } = useSearchUsersWithPlanInfo(token!, {
    searchTerm: search,
    organizationIds: selectedOrganizations,
    perPage: rowsPerPage,
    page: page + 1,
    includeWithoutPlans: planFilters.includes('withoutPlans'),
    includeUnapproved: planFilters.includes('unapproved'),
    includeApproved: planFilters.includes('approved'),
  })

  const organizationIdsWithMembersWrite =
    profile != null ? getOrganizationIdsWithPermissions(profile, [Permissions.MEMBERS_WRITE]) : []
  const organizationIdsWithPlansRead =
    profile != null ? getOrganizationIdsWithPermissions(profile, [Permissions.PLANS_READ]) : []
  const organizationIdsWithPlanWrite =
    profile != null ? getOrganizationIdsWithPermissions(profile, [Permissions.PLANS_WRITE]) : []

  const canManageTeamStructure = organizationIdsWithMembersWrite.length > 0
  const canManagePlans = organizationIdsWithPlansRead.length > 0

  const organizationMap = profile != null ? getOrganizationLabelMap(profile) : {}

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    setRowsPerPage(+event?.target?.value!)
    setPage(0)
  }

  const handleSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])

  const handleFilterChange = (event: SelectChangeEvent<string[]>) => {
    setPlanFilters(event.target.value as string[])
  }

  // table columns
  interface ColumnProps {
    id: string
    label: string
    minWidth: number
    align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
    format?: (value: any, row: any) => string | boolean | JSX.Element
  }

  const navigate = useNavigate()

  const columns: ColumnProps[] = [
    { id: 'name', label: 'Name', minWidth: 200, format: (value: any, row: any) => `${row.firstName} ${row.lastName}` },
    {
      id: 'organization',
      label: 'Team',
      minWidth: 170,
      format: (value: any, row: UserWithPlanInfo) => row.organizationName,
    },
    { id: 'position', label: 'Position', minWidth: 170 },
    { id: 'email', label: 'Email', minWidth: 170 },
    {
      id: 'id',
      label: 'Actions',
      minWidth: 100,
      format: (userId: string, row: UserWithPlanInfo) => {
        const hasApprovedPlan = row.planApproved
        const hasUnapprovedPlan = row.approvalBatchId != null && !row.planApproved
        const hasNoPlan = !row.approvalBatchId

        return (
          <Box display="flex">
            {hasApprovedPlan && organizationIdsWithPlansRead.includes(row.organizationId) && (
              <Tooltip title="View Employee Compensation Plan">
                <IconButton
                  color="primary"
                  onClick={() => {
                    startTransition(() => {
                      navigate(`/employee/${userId}/compplan`)
                    })
                  }}
                >
                  <EyeOutlined />
                </IconButton>
              </Tooltip>
            )}
            {hasUnapprovedPlan && (
              <Tooltip title="Review Unapproved Plan">
                <IconButton
                  color="primary"
                  onClick={() => {
                    startTransition(() => {
                      // Note: You'll need to replace this with the actual way to get the approval ID
                      navigate(`/admin/approvals/${row.approvalBatchId}/edit`)
                    })
                  }}
                >
                  <AuditOutlined />
                </IconButton>
              </Tooltip>
            )}
            {hasNoPlan && organizationIdsWithPlanWrite.includes(row.organizationId) && (
              <Tooltip title="Create New Plan">
                <IconButton
                  color="primary"
                  onClick={() => {
                    startTransition(() => {
                      navigate(`/admin/templates/select/${userId}`)
                    })
                  }}
                >
                  <FormOutlined />
                </IconButton>
              </Tooltip>
            )}
            {!hasNoPlan && organizationIdsWithPlanWrite.includes(row.organizationId) && (
              <Tooltip title="Edit Employee Compensation Plan">
                <IconButton
                  color="primary"
                  onClick={() => {
                    startTransition(() => {
                      navigate(`/create-comp-plan?userId=${userId}`)
                    })
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
            )}
            {organizationIdsWithMembersWrite.includes(row.organizationId) && (
              <Tooltip title="Edit Employee Profile">
                <IconButton
                  color="primary"
                  onClick={() => {
                    startTransition(() => {
                      navigate(`/admin/users/profile?userId=${userId}`)
                    })
                  }}
                >
                  <UserOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )
      },
    },
  ]

  return (
    <Box>
      <Grid container>
        <Grid item xs={4}>
          <SimpleTitle title="Team" />
        </Grid>
        <Grid item xs={6}></Grid>
        {canManageTeamStructure && (
          <Grid item xs={2}>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<TeamOutlined />}
              onClick={() => navigate('/admin/organizations/manage')}
              sx={{ borderColor: 'transparent', whiteSpace: 'nowrap', width: '100%' }}
            >
              Manage Team Structure
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={4} mb={3}>
        <Grid item xs={3}>
          <SearchInput key="search-input" onSearch={handleSearch} initialValue={search} />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3} md={canManageTeamStructure ? (canManagePlans ? 3 : 4) : canManagePlans ? 4 : 6}>
          <MultiOrganizationSelector
            userProfile={profile}
            selectedOrganizationIds={selectedOrganizations}
            handleChange={setSelectedOrganizations}
          />
        </Grid>
        {canManagePlans ? (
          <Grid item xs={2}>
            <FormControl variant="standard" sx={{ width: '100%' }}>
              <InputLabel>Select Plan Filters</InputLabel>
              <Select
                labelId="filter-label"
                variant="standard"
                multiple
                value={planFilters}
                onChange={handleFilterChange}
                renderValue={(selected) => {
                  const count = selected.length
                  return `Filtered Plans (${count})`
                }}
                fullWidth
              >
                {[
                  { value: 'withoutPlans', label: 'No Plan' },
                  { value: 'unapproved', label: 'Plan Not Approved' },
                  { value: 'approved', label: 'Plan Approved' },
                ].map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox checked={planFilters.indexOf(option.value) > -1} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          <Grid item xs={2}></Grid>
        )}
        <Grid item xs={1}>
          <Tooltip title="Reset Filters">
            <IconButton
              color="primary"
              onClick={() => {
                setSelectedOrganizations([])
                setPlanFilters(['withoutPlans', 'unapproved', 'approved'])
                setSearch('')
              }}
              sx={{ mt: 2 }}
            >
              <CloseCircleOutlined />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      {isLoading ? (
        <Grid container spacing={12}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex', // Enables flexbox
              justifyContent: 'center', // Centers horizontally
              alignItems: 'center', // Centers vertically
              height: '100%', // Takes full height of the container
            }}
          >
            <CircularProgress color="primary" />
            Loading
          </Grid>
        </Grid>
      ) : (
        <Paper>
          {/* table */}
          <TableContainer sx={{ maxHeight: 'calc(100vh - 400px)', minHeight: '300px' }}>
            <Table stickyHeader size="small" aria-label="sticky table">
              <TableHead
                sx={{
                  '& th': {
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `2px solid ${theme.palette.divider} !important`,
                  },
                }}
              >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      id={'cell-col-' + (column.id || '')}
                      sx={{
                        minWidth: column.minWidth,
                        position: 'sticky !important',
                      }}
                      key={column.id}
                      align={column.align}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.length > 0 ? (
                  users.map((row: KeyedObject) => (
                    <TableRow
                      id={'table-row-' + row.id}
                      sx={{ py: 3 }}
                      role="checkbox"
                      tabIndex={-1}
                      key={'table-row-' + row.id}
                    >
                      {columns.map((column) => {
                        let value
                        if (column.id === 'name') {
                          value = `${row.firstName} ${row.lastName}`
                        } else if (column.id === 'organization') {
                          const orgId = row.metadata?.organizationId
                          value = orgId ? organizationMap[orgId] || '' : ''
                        } else {
                          value = row[column.id]
                        }
                        return (
                          <TableCell id={'cell-' + column.id} key={'cell-' + column.id} align={column.align}>
                            {column.format ? column.format(value, row) : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" sx={{ px: '20px' }}>
            {canManageTeamStructure ? (
              <AnimateButton>
                <Tooltip title="Add a new team member">
                  <Button
                    variant="contained"
                    startIcon={<PlusOutlined />}
                    color="primary"
                    onClick={() =>
                      startTransition(() => {
                        navigate('/admin/users/profile')
                      })
                    }
                  >
                    Team Member
                  </Button>
                </Tooltip>
              </AnimateButton>
            ) : (
              <div />
            )}
            {/* table pagination */}
            <TablePagination
              rowsPerPageOptions={[50, 100, 200]}
              component="div"
              count={pagination.totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
          {/* table pagination */}
        </Paper>
      )}
    </Box>
  )
}

export default Organization
