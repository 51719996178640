import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { Period } from 'types/periods'

interface PeriodsTableProps {
  periods: Period[]
}

const PeriodsTable: React.FC<PeriodsTableProps> = ({ periods }) => {
  const [expandedRows, setExpandedRows] = React.useState<Set<string>>(new Set())

  const toggleRow = (periodId: string) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(periodId)) {
        newSet.delete(periodId)
      } else {
        newSet.add(periodId)
      }
      return newSet
    })
  }

  const renderPeriodRows = (period: Period, depth = 0) => {
    const rows = []
    const isExpanded = expandedRows.has(period.id || '')

    rows.push(
      <TableRow key={period.id}>
        <TableCell style={{ paddingLeft: `${depth * 20}px` }}>
          {period.children && period.children.length > 0 && (
            <IconButton size="small" onClick={() => toggleRow(period.id || '')}>
              {isExpanded ? <DownOutlined /> : <RightOutlined />}
            </IconButton>
          )}
          {period.label}
        </TableCell>
        <TableCell>{period.startDate}</TableCell>
        <TableCell>{period.endDate}</TableCell>
      </TableRow>
    )

    if (isExpanded && period.children) {
      period.children.forEach((child) => {
        rows.push(...renderPeriodRows(child, depth + 1))
      })
    }

    return rows
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Period</TableCell>
          <TableCell>Start Date</TableCell>
          <TableCell>End Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{periods.map((period) => renderPeriodRows(period))}</TableBody>
    </Table>
  )
}

export default PeriodsTable
