import { Box, Button, CircularProgress, Grid, Step, StepLabel, Stepper, Tooltip, useTheme } from '@mui/material'
import { useGlobalState } from 'contexts/GlobalStateContext'
import { fetchWithToken, putWithToken } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import { useFetchOrganizationCoreSettings } from 'hooks/useOrganizations'
import { usePeriods } from 'hooks/usePeriods'
import { useProfile } from 'hooks/useProfile'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { VariableAllocationTable } from 'types/comp-allocations'
import { CompensationTemplate, UpdateCompensationTemplate } from 'types/comp-template'
import { ExpandedTarget } from 'types/components'
import { Organization, OrganizationCoreSetting } from 'types/organization'
import { Period, createLabelToIdMap } from 'types/periods'
import { Permissions } from 'types/permissions'
import { UserRole } from 'types/role'
import TemplateComponents from '../CreateTemplate/TemplateComponents'
import TemplateDetails from '../CreateTemplate/TemplateDetails'
import TemplateReview from '../CreateTemplate/TemplateReview'

const EditTemplate: React.FC = () => {
  const { templateId } = useParams<{ templateId: string }>()
  const theme = useTheme()
  const navigate = useNavigate()
  const { token } = useAuth()
  const { profile, isLoading: isLoadingProfile } = useProfile(token!)
  const { purcentAppState } = useGlobalState()
  const { periods, isLoadingPeriods } = usePeriods(
    token!,
    purcentAppState?.selectedOrganization?.rootOrganizationId || ''
  )

  const [isLoadingTemplate, setIsLoadingTemplate] = useState(true)
  const [activeStep, setActiveStep] = useState(0)
  const [label, setLabel] = useState('')
  const [position, setPosition] = useState('')
  const [organizationIds, setOrganizationIds] = useState<string[]>([])
  const [rootOrganizationId, setRootOrganizationId] = useState('')
  const [periodId, setPeriodId] = useState('')
  const [rootPeriodId, setRootPeriodId] = useState('')
  const [basePlus, setBasePlus] = useState(true)
  const [variablePercent, setVariablePercent] = useState(0.1)
  const [minVariablePercent, setMinVariablePercent] = useState(0.05)
  const [maxVariablePercent, setMaxVariablePercent] = useState(0.15)
  const [period, setPeriod] = useState<Period | null>(null)
  const [allocationTables, setAllocationTables] = useState<VariableAllocationTable[]>([])
  const [exampleSalary, setExampleSalary] = useState<number>(100000)
  const [exampleBonusAmount, setExampleBonusAmount] = useState<number>(10000)
  const [defaultVariableCap, setDefaultVariableCap] = useState<number>(0.1)
  const [minVariableCap, setMinVariableCap] = useState<number>(0.05)
  const [maxVariableCap, setMaxVariableCap] = useState<number>(0.15)
  const [createdAt, setCreatedAt] = useState('')
  const [updatedAt, setUpdatedAt] = useState('')
  const [isValid, setIsValid] = useState(true)
  const [allSelectedTargets, setAllSelectedTargets] = useState<ExpandedTarget[]>([])

  const [currency, setCurrency] = useState<string>('USD')
  const [currencyDecimals, setCurrencyDecimals] = useState<number>(2)

  const [coreSettings, setCoreSettings] = useState<OrganizationCoreSetting | null>(null)
  const { coreSettings: fetchedCoreSettings, isLoading: isLoadingCoreSettings } = useFetchOrganizationCoreSettings(
    organizationIds.length > 0 ? [organizationIds[0]] : [],
    token!
  )

  const [initializedFromCore, setInitializedFromCore] = useState(false)

  const addTargetIfNotExists = (newTarget: ExpandedTarget) => {
    setAllSelectedTargets((prevTargets) => {
      const targetExists = prevTargets.some(
        (target) =>
          target.component.id === newTarget.component.id && target.target.periodId === newTarget.target.periodId
      )

      if (!targetExists) {
        return [...prevTargets, newTarget]
      }

      return prevTargets
    })
  }

  const getActiveTargets = (): ExpandedTarget[] => {
    return allSelectedTargets.filter((target) => {
      const targetSlug = target.target.slug
      const componentSlug = target.component.slug

      return allocationTables.some((table) =>
        table.rows.some((row) => {
          const requirementMatch = row.formulaRequirements.some(
            (req) => req.includes(targetSlug) || req.includes(componentSlug)
          )
          const optionMatch = row.formulaOptions.some((opt) => opt.includes(targetSlug) || opt.includes(componentSlug))
          return requirementMatch || optionMatch
        })
      )
    })
  }

  const fetchTemplateData = async () => {
    setIsLoadingTemplate(true)
    try {
      const data = await fetchWithToken(`compensation/templates/${templateId}`, token!, null)

      setLabel(data.template.label)
      setPosition(data.template.position)
      setOrganizationIds(data.template.organizationIds)
      setRootOrganizationId(data.template.rootOrganizationId)
      setPeriodId(data.template.rootPeriodId)
      setRootPeriodId(data.template.rootPeriodId)
      setBasePlus(data.template.basePlus)
      setVariablePercent(data.template.variablePercent)
      setMinVariablePercent(data.template.minVariablePercent)
      setMaxVariablePercent(data.template.maxVariablePercent)
      setDefaultVariableCap(data.template.variableCap)
      setMinVariableCap(data.template.minVariableCap)
      setMaxVariableCap(data.template.maxVariableCap)
      setCreatedAt(data.template.createdAt)
      setUpdatedAt(data.template.updatedAt)
      setAllocationTables(data.template.variableAllocations)

      const newExpandedTargets = data.targets.map((target: any) => ({
        target: target,
        component: data.components.find((comp: any) => comp.id === target.componentId),
      }))
      setAllSelectedTargets(newExpandedTargets)

      setIsLoadingTemplate(false)

      console.log('Template loaded: ', label)
    } catch (error) {
      console.error('Error fetching template data:', error)
      navigate('/admin/templates')
    }
  }

  useEffect(() => {
    if (templateId) fetchTemplateData()
  }, [templateId])

  useEffect(() => {
    console.log('Label:', label)
    console.log('Position:', position)
    console.log('Period: ', period)
    console.log('PeriodId: ', periodId)
    // Add any other properties you want to log or actions you want to perform
  }, [label, position]) // Add any other dependencies here

  useEffect(() => {
    if (fetchedCoreSettings && fetchedCoreSettings.length > 0) {
      setCoreSettings(fetchedCoreSettings[0])
    }
  }, [fetchedCoreSettings])

  useEffect(() => {
    if (coreSettings && !initializedFromCore) {
      setCurrency(coreSettings.currency)
      setCurrencyDecimals(coreSettings.currencyDecimals)

      // Mark as initialized
      setInitializedFromCore(true)
    }
  }, [coreSettings, initializedFromCore])

  const organizationMap: Record<string, Organization> = profile.roles.reduce(
    (acc: Record<string, Organization>, role: UserRole) => {
      if (role.role.permissions.includes(Permissions.PERIODS_READ)) {
        acc[role.organization.id] = role.organization
      }
      return acc
    },
    {} as Record<string, Organization>
  )

  const organizationLabels: Record<string, string> = profile.roles.reduce(
    (acc: Record<string, string>, role: UserRole) => {
      if (role.role.permissions.includes(Permissions.PERIODS_READ)) {
        acc[role.organization.id] = role.organization.name
      }
      return acc
    },
    {} as Record<string, string>
  )

  const fiscalYears: Record<string, Period> = periods?.periods?.reduce(
    (acc: Record<string, Period>, period: Period) => {
      acc[period.label] = period
      return acc
    },
    {} as Record<string, Period>
  )

  useEffect(() => {
    if (rootOrganizationId && periodId) {
      setAllSelectedTargets((prevTargets) => {
        return prevTargets.filter(
          (target) => target.target.rootOrganizationId === rootOrganizationId && target.target.rootPeriodId === periodId
        )
      })
    }
  }, [rootOrganizationId, periodId])

  useEffect(() => {}, [purcentAppState?.selectedOrganization, allocationTables])

  const stepLabels = ['Template Details', 'Allocations', 'Review Template']

  const periodIdToLabelMap = (period: Period | null) => {
    if (period == null) {
      return {}
    }

    return Object.entries(createLabelToIdMap(period)).reduce(
      (acc, [key, value]) => {
        acc[value] = key
        return acc
      },
      {} as Record<string, string>
    )
  }

  const periodLabels = periodIdToLabelMap(period)

  const stepContent = [
    <TemplateDetails
      label={label || ''}
      position={position || ''}
      organizationIds={organizationIds}
      rootOrganizationId={rootOrganizationId || ''}
      periodId={periodId || ''}
      rootPeriodId={rootPeriodId || ''}
      setLabel={setLabel}
      setPosition={setPosition}
      setOrganizationIds={setOrganizationIds}
      setRootOrganizationId={setRootOrganizationId}
      setPeriodId={setPeriodId}
      setRootPeriodId={setRootPeriodId}
      basePlus={basePlus}
      setBasePlus={setBasePlus}
      variablePercent={variablePercent}
      setVariablePercent={setVariablePercent}
      minVariablePercent={minVariablePercent}
      setMinVariablePercent={setMinVariablePercent}
      maxVariablePercent={maxVariablePercent}
      setMaxVariablePercent={setMaxVariablePercent}
      defaultVariableCap={defaultVariableCap}
      setDefaultVariableCap={setDefaultVariableCap}
      minVariableCap={minVariableCap}
      maxVariableCap={maxVariableCap}
      setMinVariableCap={setMinVariableCap}
      setMaxVariableCap={setMaxVariableCap}
      organizationMap={organizationMap}
      fiscalYears={fiscalYears}
      setPeriod={setPeriod}
      exampleSalary={exampleSalary}
      setExampleSalary={setExampleSalary}
      setExampleBonusAmount={setExampleBonusAmount}
      userProfile={profile}
      theme={theme}
      currency={currency}
      currencyDecimals={currencyDecimals}
    />,
    <TemplateComponents
      templateName={label}
      period={period}
      allocationTables={allocationTables}
      setAllocationTables={setAllocationTables}
      targets={allSelectedTargets}
      organizationLabels={organizationLabels}
      periodLabels={periodLabels}
      exampleBonusAmount={exampleBonusAmount}
      exampleSalary={exampleSalary}
      variableCompensation={variablePercent}
      setIsValid={setIsValid}
      addTargetIfNotExists={addTargetIfNotExists}
      theme={theme}
      currency={currency}
      currencyDecimals={currencyDecimals}
    />,
    <TemplateReview
      allocationTables={allocationTables}
      periodIdToLabelMap={periodLabels}
      label={label}
      position={position}
      periodId={periodId}
      basePlus={basePlus}
      exampleSalary={exampleSalary}
      variableCapMin={minVariableCap}
      variableCapDefault={defaultVariableCap}
      variableCapMax={maxVariableCap}
      variablePercentMin={minVariablePercent}
      variablePercentMax={maxVariablePercent}
      variablePercentDefault={variablePercent}
      organizationNames={organizationIds.map((id) => organizationMap[id]?.name || '')}
      currency={currency}
      currencyDecimals={currencyDecimals}
    />,
  ]

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
    navigate('/admin/templates')
  }

  const collectComponentIds = () => {
    const componentIds = getActiveTargets().map((target) => target.component.id)
    return [...new Set(componentIds)]
  }

  const handleSubmit = () => {
    const template: CompensationTemplate = {
      id: templateId,
      revision: 0,
      createdAt: createdAt,
      updatedAt: updatedAt,
      rootOrganizationId: rootOrganizationId,
      organizationIds: organizationIds,
      rootPeriodId: periodId,
      label: label,
      periodId: periodId,
      position: position,
      basePlus: basePlus,
      variablePercent: variablePercent,
      minVariablePercent: minVariablePercent,
      maxVariablePercent: maxVariablePercent,
      variableCap: defaultVariableCap,
      minVariableCap: minVariableCap,
      maxVariableCap: maxVariableCap,
      variableAllocations: allocationTables,
    }

    const componentIds = collectComponentIds()

    console.log(componentIds)
    console.log(template)
    console.log('Saving Period: ', template.periodId, ' ', template.rootPeriodId)

    putWithToken('compensation/templates/' + templateId, token!, {
      template: template,
      components: componentIds,
    } as UpdateCompensationTemplate)
      .then((response) => {
        console.log('Template submitted successfully:', response)
      })
      .catch((error) => {
        console.error('Error submitting template:', error)
      })
      .then(() => navigate('/admin/templates'))
  }

  return isLoadingProfile || isLoadingPeriods || isLoadingTemplate || isLoadingCoreSettings ? (
    <Grid container spacing={12}>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress color="primary" />
        Loading
      </Grid>
    </Grid>
  ) : (
    <>
      <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
        {stepLabels.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {stepContent[activeStep]}
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button variant="contained" color="warning" onClick={handleReset}>
          Cancel
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button variant="outlined" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
        {activeStep === stepLabels.length - 1 ? (
          <Button variant="contained" onClick={handleSubmit}>
            Finish
          </Button>
        ) : (
          <Tooltip
            title={
              activeStep === 0 && (!periodId || organizationIds.length === 0)
                ? 'Select both a period and at least one organization to proceed'
                : activeStep === 1 && !isValid
                  ? 'Ensure allocations are valid and complete'
                  : ''
            }
            disableHoverListener={
              !((activeStep === 0 && (!periodId || organizationIds.length === 0)) || (activeStep === 1 && !isValid))
            }
          >
            <span>
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={
                  (activeStep === 0 && (!periodId || organizationIds.length === 0)) || (activeStep === 1 && !isValid)
                }
              >
                Next
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
    </>
  )
}

export default EditTemplate
