import { TextField } from '@mui/material'
import React from 'react'
import { NumericFormat } from 'react-number-format'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumericFormatCustom = React.forwardRef<HTMLInputElement, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: { value: string }) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      valueIsNumericString
      decimalScale={0}
      allowNegative={false}
    />
  )
})

interface WholeNumberFieldProps {
  label?: string
  value: string
  onChange: (value: string) => void
  name: string
  style?: React.CSSProperties // Add the optional style property
}

const WholeNumberField: React.FC<WholeNumberFieldProps> = ({
  label,
  value,
  onChange,
  name,
  style, // Destructure the style prop
}) => {
  return (
    <TextField
      fullWidth
      label={label}
      variant="standard"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      name={name}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
      }}
      style={style} // Pass the style prop
    />
  )
}

export default WholeNumberField
