import { DeleteOutlined } from '@ant-design/icons'
import AddIcon from '@mui/icons-material/Add'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import AnimateButton from 'components/@extended/AnimateButton'
import SingleOrganizationSelector from 'components/SingleOrganizationSelector'
import dayjs, { Dayjs } from 'dayjs'
import useAuth from 'hooks/useAuth'
import { useFetchLedgerAccounts } from 'hooks/useLedgerAccounts'
import { useProfile, useRoles } from 'hooks/useProfile'
import { useCreateUser, useDeleteUserFromOrganization, useFetchUser, useUpdateUser } from 'hooks/useUsers'
import { FC, startTransition, useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Permissions } from 'types/permissions'
import { UserData } from 'types/user'
import { getTopParentOrganizationId, getUniqueOrganizationRoles } from 'types/user-profile'

const CreateUser: FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const userId = searchParams.get('userId') || undefined
  const { token } = useAuth()
  const { profile, isLoading: isLoadingProfile } = useProfile(token!)
  const { roles, isLoading: isLoadingRoles, isError: isRolesError } = useRoles(token!, true)
  const [ledgerAccountId, setLedgerAccountId] = useState('')
  const [rootOrganizationId, setRootOrganizationId] = useState<string | null>(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string; severity: 'success' | 'error' }>({
    open: false,
    message: '',
    severity: 'success',
  })
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [startDate, setStartDate] = useState<Dayjs | null>(null)

  console.log('Current state:', { userId, token, rootOrganizationId, ledgerAccountId })

  const {
    ledgerAccounts,
    isLoading: isLoadingLedgerAccounts,
    refetch: refetchLedgerAccounts,
  } = useFetchLedgerAccounts(rootOrganizationId || '', token!, !!rootOrganizationId)

  console.log('Ledger accounts:', { ledgerAccounts, isLoadingLedgerAccounts })

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [position, setPosition] = useState('')
  const [teamAssignments, setTeamAssignments] = useState<UserData['roles']>([
    { organizationId: '', roleId: '', delete: false },
  ])
  const [endDate, setEndDate] = useState<Dayjs | null>(null)

  const { userProfile, isLoading: isLoadingUser } = useFetchUser(
    token!,
    userId || null,
    [['explicitRolesOnly', 'true']],
    !!userId
  )

  const { createUser, isLoading: isCreatingUser } = useCreateUser(token!)
  const { updateUser, isLoading: isUpdatingUser } = useUpdateUser(token!)
  const { deleteUser, isLoading: isDeleting } = useDeleteUserFromOrganization(token!)

  useEffect(() => {
    console.log('Profile effect triggered', { profile })
    if (profile) {
      const firstRootOrgId = getTopParentOrganizationId(profile)
      console.log('Setting rootOrganizationId:', firstRootOrgId)
      setRootOrganizationId(firstRootOrgId)
    }
  }, [profile])

  const fetchLedgerAccounts = useCallback(() => {
    if (rootOrganizationId && token && !ledgerAccounts) {
      console.log('Fetching ledger accounts')
      refetchLedgerAccounts()
    }
  }, [rootOrganizationId, token, refetchLedgerAccounts])

  useEffect(() => {
    console.log('Root organization or token effect triggered', { rootOrganizationId, token })
    fetchLedgerAccounts()
  }, [fetchLedgerAccounts])

  useEffect(() => {
    if (userProfile) {
      setFirstName(userProfile.user.firstName || '')
      setLastName(userProfile.user.lastName || '')
      setEmail(userProfile.user.email || '')
      setStartDate(userProfile.user.startDate ? dayjs(userProfile.user.startDate) : null)
      setPosition(userProfile.user.position || '')
      setLedgerAccountId(userProfile.user.ledgerAccountId || '')
      setTeamAssignments(
        userProfile.roles.map((role) => ({
          organizationId: role.organization.id,
          roleId: role.role.id,
          delete: false,
        })) || [{ organizationId: '', roleId: '', delete: false }]
      )
      setEndDate(userProfile.user.endDate ? dayjs(userProfile.user.endDate) : null)

      // Set rootOrganizationId based on the first role's organization
      if (userProfile.roles.length > 0) {
        setRootOrganizationId(userProfile.roles[0].organization.rootOrganizationId)
      }
    }
  }, [userProfile])

  useEffect(() => {
    if (token && !roles.length && !isLoadingRoles) {
      // Force a re-render to trigger a new roles fetch
      setRootOrganizationId((prevId) => prevId)
    }
  }, [token, roles, isLoadingRoles])

  // Add this function to check if the user can change roles
  const canChangeRoles = (organizationId: string): string[] => {
    if (!profile) {
      return []
    }
    const roleForOrganization = getUniqueOrganizationRoles(profile).find(
      (userRole) => userRole.organization.id === organizationId
    )

    if (!roleForOrganization) {
      return []
    }

    if (roleForOrganization.role.name === 'Super-Admin') {
      return ['Super-Admin', 'Admin', 'Sub-Admin', 'Member', 'Guest']
    }

    if (roleForOrganization.role.name === 'Admin') {
      return ['Admin', 'Sub-Admin', 'Member', 'Guest']
    }

    if (roleForOrganization.role.name === 'Sub-Admin') {
      return ['Member', 'Guest']
    }

    return []
  }

  // Add this function to check for MEMBER_WRITE permission
  const hasMemberWritePermission = (organizationId: string): boolean => {
    if (!profile) {
      return false
    }
    const roleForOrganization = getUniqueOrganizationRoles(profile).find(
      (userRole) => userRole.organization.id === organizationId
    )
    return roleForOrganization?.role.permissions.includes(Permissions.MEMBERS_WRITE) || false
  }
  const handleTeamAssignmentChange = (
    index: number,
    field: 'organizationId' | 'roleId' | 'delete',
    value: string | boolean
  ) => {
    const newAssignments = [...teamAssignments]
    if (field === 'delete') {
      newAssignments[index][field] = value as boolean
    } else {
      newAssignments[index][field] = value as string

      // Reset roleId when organizationId changes
      if (field === 'organizationId') {
        newAssignments[index].roleId = ''
      }
    }
    setTeamAssignments(newAssignments)
  }

  const addTeamAssignment = () => {
    setTeamAssignments([...teamAssignments, { organizationId: '', roleId: '', delete: false }])
  }

  const removeTeamAssignment = (index: number) => {
    const newAssignments = [...teamAssignments]
    newAssignments[index].delete = !newAssignments[index].delete
    setTeamAssignments(newAssignments)
  }

  const handleDateChange = (newValue: Dayjs | null) => {
    setStartDate(newValue)
  }

  // Add this helper function before isFormValid
  const validateMemberRole = (assignments: UserData['roles']): boolean => {
    const memberRoleCount = assignments
      .filter((assignment) => !assignment.delete)
      .filter((assignment) => {
        const role = roles.find((r) => r.id === assignment.roleId)
        return role?.name === 'Member'
      }).length

    return memberRoleCount === 1
  }

  const isFormValid = () => {
    const hasMemberRole = validateMemberRole(teamAssignments)

    return (
      firstName.trim() !== '' &&
      lastName.trim() !== '' &&
      email.trim() !== '' &&
      position.trim() !== '' &&
      startDate !== null &&
      ledgerAccountId.trim() !== '' &&
      hasMemberRole && // Add member role validation
      teamAssignments.some((assignment) =>
        userId
          ? assignment.organizationId !== '' && assignment.roleId !== ''
          : !assignment.delete && assignment.organizationId !== '' && assignment.roleId !== ''
      )
    )
  }

  const handleSubmit = async () => {
    if (!startDate) return

    if (!validateMemberRole(teamAssignments)) {
      setErrorMessage('User must have exactly one "Member" role')
      return
    }

    const userData = {
      firstName,
      lastName,
      email,
      position,
      startDate: startDate.toISOString(),
      endDate: endDate?.toISOString() || null,
      ledgerAccountId,
      roles: teamAssignments.filter((assignment) => !assignment.delete),
    }

    try {
      if (userId) {
        await updateUser(userId, userData)
        setSnackbar({
          open: true,
          message: 'User updated successfully',
          severity: 'success',
        })
      } else {
        await createUser(userData)
        setSnackbar({
          open: true,
          message: 'User created successfully',
          severity: 'success',
        })
        startTransition(() => {
          navigate('/organization')
        })
      }
    } catch (error) {
      console.error('Error handling user action:', error)
      setErrorMessage('An error occurred while saving the user')
    }
  }

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true)
  }

  const handleDeleteConfirm = async () => {
    if (!userId || !rootOrganizationId) return

    try {
      const success = await deleteUser(userId, rootOrganizationId)
      if (success) {
        setDeleteDialogOpen(false)
        setSnackbar({
          open: true,
          message: 'User archived successfully',
          severity: 'success',
        })
        startTransition(() => {
          navigate('/organization')
        })
      } else {
        setSnackbar({
          open: true,
          message: 'Failed to archive user',
          severity: 'error',
        })
      }
    } catch (error) {
      console.error('Error archiving user:', error)
      setSnackbar({
        open: true,
        message: 'An error occurred while trying to archive the user',
        severity: 'error',
      })
    }
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbar({ ...snackbar, open: false })
  }

  const getValidationMessages = () => {
    const messages: string[] = []

    if (firstName.trim() === '') messages.push('First name is required')
    if (lastName.trim() === '') messages.push('Last name is required')
    if (email.trim() === '') messages.push('Email is required')
    if (position.trim() === '') messages.push('Position is required')
    if (!startDate) messages.push('Start date is required')
    if (ledgerAccountId.trim() === '') messages.push('Ledger account is required')

    const memberRoleCount = teamAssignments
      .filter((assignment) => !assignment.delete)
      .filter((assignment) => {
        const role = roles.find((r) => r.id === assignment.roleId)
        return role?.name === 'Member'
      }).length

    if (memberRoleCount === 0) messages.push('User must have one "Member" role')
    if (memberRoleCount > 1) messages.push('User cannot have more than one "Member" role')

    if (
      !teamAssignments.some((assignment) =>
        userId
          ? assignment.organizationId !== '' && assignment.roleId !== ''
          : !assignment.delete && assignment.organizationId !== '' && assignment.roleId !== ''
      )
    ) {
      messages.push('At least one team assignment is required')
    }

    return messages.join('\n')
  }

  if (isLoadingProfile || isLoadingRoles || isLoadingLedgerAccounts || isLoadingUser) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    )
  }

  if (isRolesError) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography color="error">Error loading roles</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h3">{userId ? 'Edit Team Member' : 'Create New Team Member'}</Typography>
        {userId && (
          <IconButton onClick={handleDeleteClick} sx={{ color: 'error.main' }}>
            <DeleteOutlined />
          </IconButton>
        )}
      </Box>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Archive Team Member</DialogTitle>
        <DialogContent>
          Are you sure you want to archive this team member? This action can be reversed by an administrator.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} disabled={isDeleting}>
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            disabled={isDeleting}
            startIcon={isDeleting ? <CircularProgress size={20} /> : null}
          >
            Archive
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            disabled={!!userId}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            label="Position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            select
            variant="standard"
            label="Ledger Account"
            value={ledgerAccountId}
            onChange={(e) => setLedgerAccountId(e.target.value)}
            fullWidth
          >
            {ledgerAccounts?.map((account) => (
              <MenuItem key={account.id} value={account.id}>
                {account.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={handleDateChange}
            sx={{ width: '100%' }}
            slotProps={{ textField: { variant: 'standard' } }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            label="End Date (optional)"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            sx={{ width: '100%' }}
            slotProps={{
              textField: { variant: 'standard' },
              field: { clearable: true },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ mt: 4, mb: 2 }}>
            <Typography variant="h6">Add to Team(s)</Typography>
          </Box>
        </Grid>
        {teamAssignments.map((assignment, index) => {
          const isNewRow = !assignment.delete && (!assignment.organizationId || !assignment.roleId)
          const canChangeRolesForOrg = assignment.organizationId ? canChangeRoles(assignment.organizationId) : []
          const hasWritePermission = isNewRow ? true : hasMemberWritePermission(assignment.organizationId)
          const currentRoleInAllowedRoles =
            isNewRow || canChangeRolesForOrg.includes(roles.find((r) => r.id === assignment.roleId)?.name || '')
          const isDisabled = assignment.delete || (!isNewRow && (!hasWritePermission || !currentRoleInAllowedRoles))

          const tooltipTitle = isDisabled ? 'Insufficient permissions or role has been disabled' : ''

          const rolesToShow = isDisabled ? roles : roles.filter((role) => canChangeRolesForOrg.includes(role.name))

          return (
            <Grid item xs={12} key={index}>
              <Tooltip title={tooltipTitle} arrow>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    opacity: isDisabled ? 0.5 : 1,
                    backgroundColor: isDisabled ? (theme) => alpha(theme.palette.action.disabled, 0.1) : 'transparent',
                  }}
                >
                  <SingleOrganizationSelector
                    userProfile={profile}
                    selectedOrganizationId={assignment.organizationId}
                    handleChange={(value) => handleTeamAssignmentChange(index, 'organizationId', value)}
                    label="Select Team"
                    showLabelAbove={true}
                    disabled={isDisabled}
                  />
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>Select Role</InputLabel>
                    <Select
                      variant="standard"
                      value={assignment.roleId}
                      onChange={(e) => handleTeamAssignmentChange(index, 'roleId', e.target.value)}
                      label="Role"
                      fullWidth
                      disabled={isDisabled || !assignment.organizationId}
                    >
                      {assignment.organizationId &&
                        rolesToShow.map((role) => (
                          <MenuItem key={role.id} value={role.id}>
                            {role.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <IconButton
                    onClick={() => removeTeamAssignment(index)}
                    disabled={isDisabled || teamAssignments.length <= 1}
                  >
                    <DeleteOutlined color={isDisabled ? 'disabled' : 'inherit'} />
                  </IconButton>
                </Box>
              </Tooltip>
            </Grid>
          )
        })}
        <Grid item xs={12}>
          <Button startIcon={<AddIcon />} onClick={addTeamAssignment} sx={{ mt: 1 }}>
            Add Another Team
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box mt={3}>
            <Stack direction="row" justifyContent={'space-between'} spacing={2}>
              <AnimateButton>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    startTransition(() => {
                      navigate('/organization')
                    })
                  }}
                >
                  Cancel
                </Button>
              </AnimateButton>
              <Tooltip title={!isFormValid() ? getValidationMessages() : ''} arrow placement="top-start">
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ mt: 2 }}
                    disabled={!isFormValid() || isCreatingUser || isUpdatingUser}
                    startIcon={isCreatingUser || isUpdatingUser ? <CircularProgress size={20} /> : null}
                  >
                    {userId ? 'Update User' : 'Create User'}
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          </Box>
        </Grid>
        {errorMessage && (
          <Grid item xs={12}>
            <Typography color="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default CreateUser
