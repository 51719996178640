import { InputAdornment, TextField } from '@mui/material'
import React from 'react'
import { NumericFormat } from 'react-number-format'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  decimalPlaces: number
}

const NumericFormatCustom = React.forwardRef<HTMLInputElement, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, decimalPlaces, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: { value: string }) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
      decimalScale={decimalPlaces}
    />
  )
})

interface PercentageFieldProps {
  label?: string
  value: string
  onChange: (value: string) => void
  name: string
  decimalPlaces?: number
  style?: React.CSSProperties // Add the optional style property
}

const PercentageField: React.FC<PercentageFieldProps> = ({
  label,
  value,
  onChange,
  name,
  decimalPlaces = 2,
  style, // Destructure the style prop
}) => {
  const handleChange = (event: { target: { name: string; value: string } }) => {
    // Convert the percentage back to a decimal
    const decimalValue = (parseFloat(event.target.value) / 100).toString()
    onChange(decimalValue)
  }

  // Convert the decimal to a percentage for display
  const displayValue = (parseFloat(value) * 100).toFixed(decimalPlaces)

  return (
    <TextField
      fullWidth
      label={label}
      variant="standard"
      value={displayValue}
      onChange={handleChange}
      name={name}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        inputProps: {
          decimalScale: decimalPlaces,
          fixedDecimalScale: true,
        },
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      style={style} // Pass the style prop
    />
  )
}

export default PercentageField
