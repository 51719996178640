import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import React, { useCallback } from 'react'

type PercentSelectorProps = {
  value: number
  setValue: React.Dispatch<React.SetStateAction<number>>
  incrementAmount: number
  min: number
  max: number
  disabled?: boolean
  reviewOnly?: boolean
}

const PercentSelector: React.FC<PercentSelectorProps> = ({
  value,
  setValue,
  incrementAmount,
  min,
  max,
  disabled = false,
  reviewOnly = false,
}) => {
  const decrementPercent = useCallback(() => {
    setValue((prevValue: number) => Math.max(min, Math.min(max, Math.round((prevValue - incrementAmount) * 100) / 100)))
  }, [min, max, incrementAmount, setValue])

  const incrementPercent = useCallback(() => {
    setValue((prevValue: number) => Math.max(min, Math.min(max, Math.round((prevValue + incrementAmount) * 100) / 100)))
  }, [min, max, incrementAmount, setValue])

  const controls = reviewOnly ? null : (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '2px' }}>
      <IconButton
        size="small"
        onClick={() => incrementPercent()}
        disabled={disabled || value >= max}
        style={{ padding: '2px' }}
      >
        <ArrowDropUp style={{ fontSize: '18px' }} />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => decrementPercent()}
        disabled={disabled || value <= min}
        style={{ padding: '2px' }}
      >
        <ArrowDropDown style={{ fontSize: '18px' }} />
      </IconButton>
    </div>
  )

  return (
    <div style={{ display: 'flex', alignItems: 'center', fontSize: '1.1rem' }}>
      <Typography color={disabled ? 'text.disabled' : 'text.primary'}>{`${(value * 100).toFixed(0)}%`}</Typography>
      {controls}
    </div>
  )
}

export default PercentSelector
