import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  useTheme,
} from '@mui/material'
import AnimateButton from 'components/@extended/AnimateButton'
import ComponentField from 'components/ComponentField'
import SimpleTitle from 'components/SimpleTitle'
import SingleOrganizationSelector from 'components/SingleOrganizationSelector'
import { useGlobalState } from 'contexts/GlobalStateContext'
import { postWithToken } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import { useGetComponentLabels } from 'hooks/useComponents' // Import the new hook
import { useFetchOrganizationCoreSettings } from 'hooks/useOrganizations'
import { useProfile } from 'hooks/useProfile'
import { useSnackbar } from 'notistack'
import { FC, startTransition, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ComponentType, getInputFormatFromComponentType } from 'types/components'
import { ThemeMode } from 'types/config'

const CreateComponent: FC = () => {
  const theme = useTheme()
  const { token } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { purcentAppState } = useGlobalState()
  const { profile, isLoading: isLoadingProfile } = useProfile(token!)

  const [selectedOrganization, setSelectedOrganization] = useState<string>('')
  const [label, setLabel] = useState<string>('')
  const [componentType, setComponentType] = useState<ComponentType>(ComponentType.COMPONENT_TYPE_INTEGER)
  const [isOrganizationLevel, setIsOrganizationLevel] = useState<boolean>(true)
  const [isTargetEditable, setIsTargetEditable] = useState<boolean>(true)
  const [restrictedVisibility, setRestrictedVisibility] = useState<boolean>(false)
  const [targetMin, setTargetMin] = useState<string>('')
  const [targetMax, setTargetMax] = useState<string>('')
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [isLabelUsed, setIsLabelUsed] = useState<boolean>(false) // New state for label validation

  const { coreSettings, isLoading: isLoadingCoreSettings } = useFetchOrganizationCoreSettings(
    selectedOrganization ? [selectedOrganization] : [],
    token!
  )

  const { labels, isLoading: isLoadingLabels } = useGetComponentLabels(token!, selectedOrganization) // Use the new hook

  const validateForm = () => {
    setIsFormValid(
      selectedOrganization !== '' &&
        label !== '' &&
        targetMin !== '' &&
        targetMax !== '' &&
        !isLabelUsed &&
        Number(targetMin) <= Number(targetMax)
    )
  }

  useEffect(() => {
    validateForm()
  }, [selectedOrganization, label, targetMin, targetMax, isLabelUsed])

  useEffect(() => {
    if (labels.includes(label)) {
      setIsLabelUsed(true)
    } else {
      setIsLabelUsed(false)
    }
  }, [label, labels])

  const handleSubmit = () => {
    const componentDTO = {
      rootOrganizationId: purcentAppState?.selectedOrganization?.rootOrganizationId || '',
      organizationId: selectedOrganization,
      ledgerAccountId: null,
      isOrganizationLevel,
      isTargetEditable,
      restrictedVisibility,
      targetMin,
      targetMax,
      label,
      format: getInputFormatFromComponentType(componentType),
      sourceType: 'organization',
      componentType,
    }

    postWithToken('components', token!, [componentDTO])
      .then(() => {
        enqueueSnackbar('Component created successfully', { variant: 'success' })
        startTransition(() => {
          navigate('/admin/components/manage')
        })
      })
      .catch((error) => {
        const errorMessage = error.message || 'Failed to create component'
        enqueueSnackbar(errorMessage, { variant: 'error' })
      })
  }

  return (
    <Box>
      <SimpleTitle title="Create Component" />
      <Box mb={3}>
        {isLoadingProfile || isLoadingCoreSettings || isLoadingLabels ? (
          <CircularProgress />
        ) : (
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SingleOrganizationSelector
                  userProfile={profile}
                  selectedOrganizationId={selectedOrganization}
                  handleChange={(value) => {
                    setSelectedOrganization(value)
                    validateForm()
                  }}
                  label="Select Organization"
                  showLabelAbove={true}
                  style={{ marginTop: 3, paddingBottom: 0 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Component Label"
                  key="label-field"
                  variant="standard"
                  fullWidth
                  value={label}
                  onChange={(e) => {
                    setLabel(e.target.value)
                    validateForm()
                  }}
                  error={isLabelUsed}
                  helperText={isLabelUsed ? 'Label is already used' : ''}
                  style={{ marginTop: 3, paddingBottom: 0 }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>Format</InputLabel>
                <Select
                  value={componentType}
                  onChange={(e) => setComponentType(e.target.value as ComponentType)}
                  fullWidth
                  variant="standard"
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor:
                            theme.palette.mode === ThemeMode.DARK
                              ? theme.palette.primary.darker
                              : theme.palette.secondary.dark,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value={ComponentType.COMPONENT_TYPE_INTEGER}>Integer</MenuItem>
                  <MenuItem value={ComponentType.COMPONENT_TYPE_CURRENCY}>Currency</MenuItem>
                  <MenuItem value={ComponentType.COMPONENT_TYPE_PERCENTAGE}>Percentage</MenuItem>
                  <MenuItem value={ComponentType.COMPONENT_TYPE_BOOL}>Boolean</MenuItem>
                  <MenuItem value={ComponentType.COMPONENT_TYPE_FLOAT}>Float</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>Type</InputLabel>
                <Select
                  value={isOrganizationLevel}
                  onChange={(e) => setIsOrganizationLevel(e.target.value === 'true')}
                  fullWidth
                  variant="standard"
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor:
                            theme.palette.mode === ThemeMode.DARK
                              ? theme.palette.primary.darker
                              : theme.palette.secondary.dark,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="true">Team</MenuItem>
                  <MenuItem value="false">Individual</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>Restricted Visibility</InputLabel>
                <Select
                  value={restrictedVisibility}
                  onChange={(e) => setRestrictedVisibility(e.target.value === 'true')}
                  fullWidth
                  variant="standard"
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor:
                            theme.palette.mode === ThemeMode.DARK
                              ? theme.palette.primary.darker
                              : theme.palette.secondary.dark,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputLabel>Is Target Editable</InputLabel>
                <Select
                  value={isTargetEditable}
                  onChange={(e) => setIsTargetEditable(e.target.value === 'true')}
                  fullWidth
                  variant="standard"
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        '& .MuiMenuItem-root:hover': {
                          backgroundColor:
                            theme.palette.mode === ThemeMode.DARK
                              ? theme.palette.primary.darker
                              : theme.palette.secondary.dark,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={4}>
                <ComponentField
                  componentType={componentType}
                  label="Target Min"
                  value={targetMin}
                  onChange={(value) => {
                    setTargetMin(value)
                    validateForm()
                  }}
                  name="targetMin"
                  disabled={!selectedOrganization || isLoadingCoreSettings}
                  currency={coreSettings?.[0]?.currency || 'USD'}
                  currencyDecimals={coreSettings?.[0]?.currencyDecimals || 0}
                  decimalPlaces={2}
                  style={{ marginTop: 4, paddingBottom: 0 }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <ComponentField
                  componentType={componentType}
                  label="Target Max"
                  value={targetMax}
                  onChange={(value) => {
                    setTargetMax(value)
                    validateForm()
                  }}
                  name="targetMax"
                  disabled={!selectedOrganization || isLoadingCoreSettings}
                  currency={coreSettings?.[0]?.currency || 'USD'}
                  currencyDecimals={coreSettings?.[0]?.currencyDecimals || 0}
                  decimalPlaces={2}
                  style={{ marginTop: 4, paddingBottom: 0 }}
                />
              </Grid>
            </Grid>
            <Box mt={3}>
              <Stack direction="row" justifyContent={'space-between'} spacing={2}>
                <AnimateButton>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      startTransition(() => {
                        navigate('/admin/components/manage')
                      })
                    }}
                  >
                    Cancel
                  </Button>
                </AnimateButton>
                <AnimateButton>
                  <Button variant="contained" onClick={handleSubmit} disabled={!isFormValid}>
                    Create Component
                  </Button>
                </AnimateButton>
              </Stack>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default CreateComponent
