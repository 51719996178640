import { Box, Button, CircularProgress, Grid, Step, StepLabel, Stepper, Tooltip, useTheme } from '@mui/material'
import { useGlobalState } from 'contexts/GlobalStateContext'
import { postWithToken } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import { useFetchExpandedTargets } from 'hooks/useComponents'
import { useFetchOrganizationCoreSettings } from 'hooks/useOrganizations'
import { usePeriods } from 'hooks/usePeriods'
import { useProfile } from 'hooks/useProfile'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { VariableAllocationTable } from 'types/comp-allocations'
import { CompensationTemplateDTO, ExpandedCompensationTemplateDTO } from 'types/comp-template'
import { ExpandedTarget } from 'types/components'
import { Organization, OrganizationCoreSetting } from 'types/organization'
import { Period, createLabelToIdMap } from 'types/periods'
import { Permissions } from 'types/permissions'
import { UserRole } from 'types/role'
import TemplateComponents from './TemplateComponents'
import TemplateDetails from './TemplateDetails'
import TemplateReview from './TemplateReview'
import AnimateButton from 'components/@extended/AnimateButton'

const CreateTemplate: React.FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { token } = useAuth()
  const { profile, isLoading } = useProfile(token!)
  const { purcentAppState } = useGlobalState()
  const { periods, isLoadingPeriods } = usePeriods(
    token!,
    purcentAppState?.selectedOrganization?.rootOrganizationId || ''
  )

  const organizationMap: Record<string, Organization> = profile.roles.reduce(
    (acc: Record<string, Organization>, role: UserRole) => {
      if (role.role.permissions.includes(Permissions.PERIODS_READ)) {
        acc[role.organization.id] = role.organization
      }
      return acc
    },
    {} as Record<string, Organization>
  )

  const organizationLabels: Record<string, string> = profile.roles.reduce(
    (acc: Record<string, string>, role: UserRole) => {
      if (role.role.permissions.includes(Permissions.PERIODS_READ)) {
        acc[role.organization.id] = role.organization.name
      }
      return acc
    },
    {} as Record<string, string>
  )

  const fiscalYears: Record<string, Period> = periods?.periods?.reduce(
    (acc: Record<string, Period>, period: Period) => {
      acc[period.label] = period
      return acc
    },
    {} as Record<string, Period>
  )

  const [activeStep, setActiveStep] = useState(0)
  const [label, setLabel] = useState('')
  const [position, setPosition] = useState('')
  const [organizationIds, setOrganizationIds] = useState<string[]>([])
  const [rootOrganizationId, setRootOrganizationId] = useState('')
  const [periodId, setPeriodId] = useState('')
  const [rootPeriodId, setRootPeriodId] = useState('')
  const [basePlus, setBasePlus] = useState(true)
  const [variablePercent, setVariablePercent] = useState(0.1)
  const [minVariablePercent, setMinVariablePercent] = useState(0.05)
  const [maxVariablePercent, setMaxVariablePercent] = useState(0.15)
  const [period, setPeriod] = useState<Period | null>(null)
  const [allocationTables, setAllocationTables] = useState<VariableAllocationTable[]>([])
  const [exampleSalary, setExampleSalary] = useState<number>(100000)
  const [exampleBonusAmount, setExampleBonusAmount] = useState<number>(10000)
  const [defaultVariableCap, setDefaultVariableCap] = useState<number>(1.5)
  const [minVariableCap, setMinVariableCap] = useState<number>(1.2)
  const [maxVariableCap, setMaxVariableCap] = useState<number>(2.0)
  const [currency, setCurrency] = useState<string>('USD')
  const [currencyDecimals, setCurrencyDecimals] = useState<number>(2)

  const [expandedTargetsOrgId, setExpandedTargetsOrgId] = useState('')
  const [expandedTargetsPeriodId, setExpandedTargetsPeriodId] = useState('')

  const [allSelectedTargets, setAllSelectedTargets] = useState<ExpandedTarget[]>([])

  const { targets, isLoadingExpandedTargets } = useFetchExpandedTargets(
    token!,
    expandedTargetsOrgId,
    expandedTargetsPeriodId
  )

  const [coreSettings, setCoreSettings] = useState<OrganizationCoreSetting | null>(null)
  const { coreSettings: fetchedCoreSettings, isLoading: isLoadingCoreSettings } = useFetchOrganizationCoreSettings(
    organizationIds.length > 0 ? [organizationIds[0]] : [],
    token!
  )

  const [initializedFromCore, setInitializedFromCore] = useState(false)

  useEffect(() => {
    if (fetchedCoreSettings && fetchedCoreSettings.length > 0) {
      setCoreSettings(fetchedCoreSettings[0])
    }
  }, [fetchedCoreSettings])

  useEffect(() => {}, [period])

  useEffect(() => {
    if (coreSettings && !initializedFromCore) {
      setBasePlus(coreSettings.defaultBasePlus)
      setExampleSalary(coreSettings.defaultExampleCompensation)
      setCurrency(coreSettings.currency)
      setCurrencyDecimals(coreSettings.currencyDecimals)

      console.log('coreSettings ', coreSettings)

      // Handle defaultVariableCompensation
      const variableCompValues = coreSettings.defaultVariableCompensation.split(',').map(Number)
      if (variableCompValues.length >= 3) {
        setMinVariablePercent(variableCompValues[0])
        setVariablePercent(variableCompValues[1])
        setMaxVariablePercent(variableCompValues[2])
      }

      // Handle defaultMaxPayout
      const maxPayoutValues = coreSettings.defaultMaxPayout.split(',').map(Number)
      if (maxPayoutValues.length >= 3) {
        setMinVariableCap(maxPayoutValues[0])
        setDefaultVariableCap(maxPayoutValues[1])
        setMaxVariableCap(maxPayoutValues[2])
      }

      // Calculate example bonus amount based on example salary and variable percent
      const calculatedBonus = coreSettings.defaultExampleCompensation * variableCompValues[1]
      setExampleBonusAmount(calculatedBonus)

      console.log('coreSettings.defaultTemplateTables:', coreSettings.defaultTemplateTables)
      console.log('allocationTables:', allocationTables)
      if (
        coreSettings.defaultTemplateTables &&
        coreSettings.defaultTemplateTables.length > 0 &&
        allocationTables.length === 0
      ) {
        const defaultTables = coreSettings.defaultTemplateTables.split(',')
        console.log('defaultTables:', defaultTables)
        setAllocationTables(
          defaultTables
            .filter((table) => table != '')
            .map((table) => table.trim())
            .map((table) => ({
              label: table,
              rows: [],
            }))
        )
      }

      // Mark as initialized
      setInitializedFromCore(true)
    }
  }, [coreSettings, initializedFromCore])

  useEffect(() => {
    if (initializedFromCore) {
      const calculatedBonus = exampleSalary * variablePercent
      setExampleBonusAmount(calculatedBonus)
    }
  }, [exampleSalary, variablePercent, initializedFromCore])

  useEffect(() => {
    if (organizationIds.length > 0 && periodId) {
      const firstOrgRoot = organizationMap[organizationIds[0]]?.rootOrganizationId
      if (firstOrgRoot) {
        setRootOrganizationId(firstOrgRoot)
        setExpandedTargetsOrgId(firstOrgRoot)
        setExpandedTargetsPeriodId(periodId)
      }
    }
  }, [organizationIds, periodId, organizationMap])

  useEffect(() => {}, [purcentAppState?.selectedOrganization, allocationTables])

  const [isAllocationValid, setIsAllocationValid] = useState(false)

  const stepLabels = ['Template Details', 'Allocations', 'Review Template']

  const addTargetIfNotExists = (newTarget: ExpandedTarget) => {
    setAllSelectedTargets((prevTargets) => {
      const targetExists = prevTargets.some(
        (target) =>
          target.component.id === newTarget.component.id && target.target.periodId === newTarget.target.periodId
      )

      if (!targetExists) {
        return [...prevTargets, newTarget]
      }

      return prevTargets
    })
  }

  const getActiveTargets = (): ExpandedTarget[] => {
    return allSelectedTargets.filter((target) => {
      const targetSlug = target.target.slug
      const componentSlug = target.component.slug

      return allocationTables.some((table) =>
        table.rows.some((row) => {
          const requirementMatch = row.formulaRequirements.some(
            (req) => req.includes(targetSlug) || req.includes(componentSlug)
          )
          const optionMatch = row.formulaOptions.some((opt) => opt.includes(targetSlug) || opt.includes(componentSlug))
          return requirementMatch || optionMatch
        })
      )
    })
  }

  const periodIdToLabelMap = (period: Period | null) => {
    if (period == null) {
      return {}
    }

    return Object.entries(createLabelToIdMap(period)).reduce(
      (acc, [key, value]) => {
        acc[value] = key
        return acc
      },
      {} as Record<string, string>
    )
  }

  const periodLabels = periodIdToLabelMap(period)

  const organizationNames: string[] | null =
    organizationIds != null && organizationIds.length > 0
      ? organizationIds.map((id) => organizationMap[id]?.name)
      : null

  console.log(periodId)

  const stepContent = [
    <TemplateDetails
      key={'template-details'}
      label={label}
      position={position}
      rootOrganizationId={rootOrganizationId}
      setRootOrganizationId={setRootOrganizationId}
      periodId={periodId}
      rootPeriodId={rootPeriodId}
      setLabel={setLabel}
      setPosition={setPosition}
      setPeriodId={setPeriodId}
      setRootPeriodId={setRootPeriodId}
      basePlus={basePlus}
      setBasePlus={setBasePlus}
      variablePercent={variablePercent}
      setVariablePercent={setVariablePercent}
      minVariablePercent={minVariablePercent}
      setMinVariablePercent={setMinVariablePercent}
      maxVariablePercent={maxVariablePercent}
      setMaxVariablePercent={setMaxVariablePercent}
      defaultVariableCap={defaultVariableCap}
      setDefaultVariableCap={setDefaultVariableCap}
      minVariableCap={minVariableCap}
      maxVariableCap={maxVariableCap}
      setMinVariableCap={setMinVariableCap}
      setMaxVariableCap={setMaxVariableCap}
      organizationMap={organizationMap}
      fiscalYears={fiscalYears}
      setPeriod={setPeriod}
      exampleSalary={exampleSalary}
      setExampleSalary={setExampleSalary}
      setExampleBonusAmount={setExampleBonusAmount}
      userProfile={profile}
      organizationIds={organizationIds}
      setOrganizationIds={setOrganizationIds}
      theme={theme}
      currency={currency}
      currencyDecimals={currencyDecimals}
    />,
    <TemplateComponents
      templateName={label}
      period={period}
      allocationTables={allocationTables}
      setAllocationTables={setAllocationTables}
      targets={targets}
      organizationLabels={organizationLabels}
      periodLabels={periodLabels}
      exampleBonusAmount={exampleBonusAmount}
      exampleSalary={exampleSalary}
      variableCompensation={variablePercent}
      setIsValid={setIsAllocationValid}
      addTargetIfNotExists={addTargetIfNotExists}
      theme={theme}
      currency={currency}
      currencyDecimals={currencyDecimals}
    />,
    <TemplateReview
      key={'template-review-component'}
      allocationTables={allocationTables}
      periodIdToLabelMap={periodLabels}
      label={label}
      position={position}
      periodId={periodId}
      basePlus={basePlus}
      exampleSalary={exampleSalary}
      variableCapMin={minVariableCap}
      variableCapDefault={defaultVariableCap}
      variableCapMax={maxVariableCap}
      variablePercentMin={minVariablePercent}
      variablePercentMax={maxVariablePercent}
      variablePercentDefault={variablePercent}
      organizationNames={organizationNames}
      currency={currency}
      currencyDecimals={currencyDecimals}
    />,
  ]

  const handleNext = () => {
    if (activeStep === 0 && (!periodId || organizationIds.length === 0)) {
      console.error('Both periodId and at least one organization must be selected before moving to the next step.')
      return
    }
    if (activeStep === 1 && !isAllocationValid) {
      console.error('Allocations must be valid before moving to the next step.')
      return
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
    navigate('/admin/templates')
  }

  const collectComponentIds = () => {
    const componentIds = getActiveTargets().map((target) => target.component.id)
    return [...new Set(componentIds)]
  }

  const handleSubmit = () => {
    const templateDTO: CompensationTemplateDTO = {
      rootOrganizationId: rootOrganizationId,
      organizationIds: organizationIds,
      rootPeriodId: periodId,
      label: label,
      periodId: periodId,
      position: position,
      basePlus: basePlus,
      variablePercent: variablePercent,
      minVariablePercent: minVariablePercent,
      maxVariablePercent: maxVariablePercent,
      variableCap: defaultVariableCap,
      minVariableCap: minVariableCap,
      maxVariableCap: maxVariableCap,
      variableAllocations: allocationTables,
    }

    const componentIds = collectComponentIds()

    postWithToken('compensation/templates', token!, {
      template: templateDTO,
      components: componentIds,
    } as ExpandedCompensationTemplateDTO)
      .then((response) => {
        console.log('Template submitted successfully:', response)
      })
      .catch((error) => {
        console.error('Error submitting template:', error)
      })
      .then(() => navigate('/admin/templates'))
  }

  return isLoading || isLoadingPeriods || isLoadingExpandedTargets || isLoadingCoreSettings ? (
    <Grid container spacing={12}>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <CircularProgress color="primary" />
        Loading
      </Grid>
    </Grid>
  ) : (
    <>
      <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
        {stepLabels.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {stepContent[activeStep]}
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <AnimateButton>
          <Button variant="contained" color="warning" onClick={handleReset}>
            Cancel
          </Button>
        </AnimateButton>
        <Box sx={{ flex: '1 1 auto' }} />
        {activeStep !== 0 ? (
          <Button variant="outlined" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
        ) : (
          <div />
        )}
        {activeStep === stepLabels.length - 1 ? (
          <Button onClick={handleSubmit}>Submit</Button>
        ) : (
          <Tooltip
            title={
              activeStep === 0 && (!periodId || organizationIds.length === 0)
                ? 'Select both a period and at least one organization to proceed'
                : activeStep === 1 && !isAllocationValid
                  ? 'Ensure allocations are valid and complete'
                  : ''
            }
            disableHoverListener={
              !(
                (activeStep === 0 && (!periodId || organizationIds.length === 0)) ||
                (activeStep === 1 && !isAllocationValid)
              )
            }
          >
            <span>
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={
                  (activeStep === 0 && (!periodId || organizationIds.length === 0)) ||
                  (activeStep === 1 && !isAllocationValid)
                }
              >
                Next
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
    </>
  )
}

export default CreateTemplate
