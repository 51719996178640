import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  styled,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import SingleOrganizationSelector from 'components/SingleOrganizationSelector'
import { useDeleteUserFromOrganization } from 'hooks/useUsers'
import { FC, useEffect, useMemo, useState } from 'react'
import { LedgerAccount } from 'types/ledger-account'
import { ExpandedOrganizationUser, Organization, OrganizationSetting, UpdateOrganizationDTO } from 'types/organization'
import { Permissions } from 'types/permissions'
import { Role } from 'types/role'
import { UserData } from 'types/user'
import { getOrganizationIdsWithPermissions, UserProfile } from 'types/user-profile'
import CreateOrUpdateUserDialog from './CreateOrUpdateUserDialog'

const LabelTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

interface EditOrganizationProps {
  organization: Organization
  users: ExpandedOrganizationUser[]
  theme: Theme
  roles: Role[]
  onDeleteOrganization: (organizationId: string) => Promise<void>
  onUserAction: (userData: UserData, isUpdate: boolean) => Promise<void>
  hasChildren: boolean
  isSubmitting: boolean
  error: Error | null
  ledgerAccounts: LedgerAccount[]
  organizationSettings: OrganizationSetting[]
  onUpdateOrganization: (organizationId: string, updateDTO: UpdateOrganizationDTO) => Promise<void>
  userProfile: UserProfile
  token: string
}

const EditOrganization: FC<EditOrganizationProps> = ({
  organization,
  users,
  theme,
  roles,
  onDeleteOrganization,
  onUserAction,
  hasChildren,
  isSubmitting,
  error,
  ledgerAccounts,
  organizationSettings,
  onUpdateOrganization,
  userProfile,
  token,
}) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedUser, setSelectedUser] = useState<ExpandedOrganizationUser | null>(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [editNameDialogOpen, setEditNameDialogOpen] = useState(false)
  const [newName, setNewName] = useState(organization.name)
  const [newParentId, setNewParentId] = useState(organization.parentId || '')

  const organizationIdsCanDeleteOrganization =
    userProfile != null ? getOrganizationIdsWithPermissions(userProfile, [Permissions.ORGANIZATION_DELETE]) : []

  const hasPermissionToDeleteOrganization = organizationIdsCanDeleteOrganization.includes(organization.id)

  useEffect(() => {
    setNewParentId(organization.parentId || '')
  }, [organization])

  const positionColors = useMemo(() => {
    const positions = [...new Set(users.map((user) => user.user.position))]
    const colors = [
      '#4e79a7',
      '#f28e2c',
      '#e15759',
      '#76b7b2',
      '#59a14f',
      '#edc949',
      '#af7aa1',
      '#ff9da7',
      '#9c755f',
      '#bab0ab',
      '#5f9ed1',
      '#c85200',
      '#898989',
      '#a2a2a2',
      '#cfcfcf',
      '#ffbc79',
    ]

    // Shuffle the colors array
    for (let i = colors.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[colors[i], colors[j]] = [colors[j], colors[i]]
    }

    return Object.fromEntries(positions.map((position, index) => [position, colors[index % colors.length]]))
  }, [users])

  const handleOpenDialog = (user?: ExpandedOrganizationUser) => {
    setSelectedUser(user || null)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSelectedUser(null)
  }

  const handleSubmitUser = async (userData: UserData) => {
    try {
      await onUserAction(userData, !!selectedUser)
      handleCloseDialog()
    } catch (error) {
      console.error('Error handling user action:', error)
      // You might want to show an error message to the user here
    }
  }

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
  }

  const handleConfirmDelete = async () => {
    await onDeleteOrganization(organization.id)
    handleCloseDeleteDialog()
  }

  const handleOpenEditNameDialog = () => {
    setEditNameDialogOpen(true)
    setNewName(organization.name)
  }

  const handleCloseEditNameDialog = () => {
    setEditNameDialogOpen(false)
  }

  const handleConfirmEditName = async () => {
    try {
      await onUpdateOrganization(organization.id, { name: newName, parentId: newParentId })
      handleCloseEditNameDialog()
    } catch (error) {
      console.error('Error updating organization name:', error)
      // You might want to show an error message to the user here
    }
  }

  const handleParentChange = (selected: string) => {
    setNewParentId(selected)
  }

  const isRootOrganization = organization.id === organization.rootOrganizationId

  // Filter out the current organization from the user profile
  const filteredUserProfile = useMemo(
    () => ({
      ...userProfile,
      roles: userProfile.roles.filter((role) => role.organization.id !== organization.id),
    }),
    [userProfile, organization.id]
  )

  const canDelete = users.length === 0 && !hasChildren && hasPermissionToDeleteOrganization

  const { deleteUser, isLoading: isDeletingUser, error: deleteUserError } = useDeleteUserFromOrganization(token)

  const handleDeleteUser = async (userId: string, organizationId: string) => {
    const success = await deleteUser(userId, organizationId)
    if (success) {
      handleCloseDialog()
      await onUserAction({ id: userId } as UserData, true)
    }
  }

  return (
    <Paper sx={{ p: 3, display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h3" gutterBottom>
            {organization.name}
          </Typography>
        </Box>
        <span>
          <IconButton onClick={handleOpenEditNameDialog} sx={{ mr: 1 }}>
            <EditOutlined />
          </IconButton>
          <Tooltip title={canDelete ? 'Delete this team' : 'Cannot delete: team has members or subordinate teams'}>
            <IconButton onClick={handleOpenDeleteDialog} disabled={!canDelete} sx={{ color: theme.palette.error.main }}>
              <DeleteOutlined />
            </IconButton>
          </Tooltip>
        </span>
      </Box>
      <Grid container alignItems="center" justifyContent="space-between" spacing={0} mb={2}>
        <Grid item xs={12} sm={5}>
          <Typography variant="body1" color="text.secondary">
            {users.length} team member{users.length !== 1 ? 's' : ''}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={7} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            color="primary"
            onClick={() => handleOpenDialog()}
            sx={{
              px: 2,
              py: 1,
              mr: 4, // Add right margin of 20px (2.5 * 8px = 20px)
              '& .MuiTypography-root': {
                whiteSpace: 'nowrap',
              },
            }}
          >
            <PlusOutlined style={{ marginRight: 8 }} />
            <Typography variant="button">New Member</Typography>
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        <List>
          {users.map((user) => (
            <ListItem
              key={user.user.id}
              onClick={() => handleOpenDialog(user)}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)', // Light grey background on hover
                },
              }}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: positionColors[user.user.position] }}>
                  {`${user.user.firstName[0]}${user.user.lastName[0]}`}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${user.user.firstName} ${user.user.lastName}`}
                secondary={`${user.user.position} | ${user.role.name}`}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <CreateOrUpdateUserDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onSubmit={handleSubmitUser}
        organizationName={organization.name}
        organizationId={organization.id}
        theme={theme}
        roles={roles}
        userProfile={userProfile}
        user={
          selectedUser
            ? {
                id: selectedUser.user.id,
                email: selectedUser.user.email,
                firstName: selectedUser.user.firstName,
                lastName: selectedUser.user.lastName,
                startDate: selectedUser.user.startDate,
                position: selectedUser.user.position,
                roles: [{ organizationId: organization.id, roleId: selectedUser.role.id }],
                ledgerAccountId: selectedUser.user.ledgerAccountId || '',
              }
            : undefined
        }
        onDelete={handleDeleteUser}
        isLoading={isSubmitting || isDeletingUser}
        error={error || deleteUserError}
        ledgerAccounts={ledgerAccounts}
        organizationSettings={organizationSettings}
        token={token}
      />
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Deletion'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {organization.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editNameDialogOpen}
        onClose={handleCloseEditNameDialog}
        aria-labelledby="edit-name-dialog-title"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="edit-name-dialog-title">Edit Team</DialogTitle>
        <DialogContent sx={{ m: 2 }}>
          <Box sx={{ mb: 3 }}>
            <LabelTypography variant="subtitle2">Team Name</LabelTypography>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              type="text"
              variant="standard"
              fullWidth
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </Box>
          {!isRootOrganization && (
            <Box>
              <LabelTypography variant="subtitle2">Parent Team</LabelTypography>
              <SingleOrganizationSelector
                userProfile={filteredUserProfile}
                selectedOrganizationId={newParentId}
                handleChange={handleParentChange}
                label="Select Parent Team"
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <Button onClick={handleCloseEditNameDialog}>Cancel</Button>
          <Button onClick={handleConfirmEditName} color="primary" disabled={isSubmitting}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export default EditOrganization
