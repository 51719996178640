import { Grid } from '@mui/material'
import { Theme } from '@mui/system'
import PlanAllocationsCard from 'components/Plan/PlanAllocationsCards'
import PlanTargetsCard from 'components/Plan/PlanTargetsCard'
import CompensationSummaryCard from 'components/cards/CompensationSummaryCard'
import { ExpandedCompensationPlan } from 'types/comp-plan'
import { LedgerAccount } from 'types/ledger-account'
import { User } from 'types/user'

const CompPlanContent = ({
  rootOrganizationId,
  token,
  user,
  compPlan,
  theme,
  ledgerAccounts,
}: {
  rootOrganizationId: string
  token: string
  user: User | undefined
  compPlan: ExpandedCompensationPlan
  theme: Theme
  ledgerAccounts: LedgerAccount[] | undefined
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CompensationSummaryCard
          name={user?.firstName + ' ' + user?.lastName}
          position={user?.position}
          effectiveDate={compPlan.plan.effectiveDate}
          basePlus={compPlan?.plan.basePlus || false}
          variablePercent={compPlan?.plan.variablePercent || 0}
          baseSalary={compPlan?.plan.baseSalary || 0}
          variableCap={compPlan?.plan.variableCap || -0}
          color={theme.palette.primary.main}
        />
      </Grid>

      <Grid item xs={12}>
        <PlanTargetsCard period={compPlan.period} plan={compPlan} />
      </Grid>

      <Grid item xs={12}>
        <PlanAllocationsCard plan={compPlan} ledgerAccounts={ledgerAccounts || []} />
      </Grid>
    </Grid>
  )
}

export default CompPlanContent
