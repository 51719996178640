import React, { useState } from 'react'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { Period } from 'types/periods'
import useAuth from 'hooks/useAuth'
import { postWithToken } from 'hooks/http'
import {
  generatePeriods,
  PeriodLevels,
  checkDateAgainstExistingPeriods,
  hasPeriodOverlapAgainstExistingPeriods,
} from 'services/periodServices'
import WarningDialog from 'components/WarningDialog'
import PeriodsTable from '../../../../components/PeriodsTable'

dayjs.extend(isBetween)
dayjs.extend(customParseFormat)

interface PeriodsWizardProp {
  rootOrganizationID: string | null
  existingPeriods: Period[]
  refetchPeriods: () => void
}

const PeriodsWizard: React.FC<PeriodsWizardProp> = ({ rootOrganizationID, existingPeriods, refetchPeriods }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [step, setStep] = useState<number>(1)
  const [length, setLength] = useState<number>(1)
  const [depth, setDepth] = useState<string>('MONTH')
  const [generatedPeriods, setGeneratedPeriods] = useState<Period[]>([])
  const [yearType, setYearType] = useState<string>('CALENDAR')
  const [selectedDate, setSelectedDate] = React.useState<dayjs.Dayjs | null>(null)
  const [openWarningDialog, setOpenWarningDialog] = useState<boolean>(false)
  const [warningDialogMessage, setWarningDialogMessage] = useState<string>('')
  const { token } = useAuth()

  const handleOpen = () => setOpen(true)

  const handleClose = () => {
    setOpen(false)
    setStep(1)
  }

  const handleNext = () => {
    if (step === 1) {
      // Generate periods based on length and depth
      if (!checkDateAgainstExistingPeriods(existingPeriods, selectedDate!)) {
        const newlyGeneratedPeriods: Period[] = []
        if (rootOrganizationID) {
          newlyGeneratedPeriods.push(
            ...generatePeriods(rootOrganizationID!, new Date(selectedDate!.toDate()), PeriodLevels.MONTH, length)
          )
          setGeneratedPeriods(newlyGeneratedPeriods)
          if (hasPeriodOverlapAgainstExistingPeriods(existingPeriods, newlyGeneratedPeriods)) {
            setWarningDialogMessage('There is an overlap in generated periods.')
            setOpenWarningDialog(true)
          } else {
            setStep(2)
          }
        } else {
          console.log('WARNING: Root Org is not set')
        }
      } else {
        setWarningDialogMessage('The selected start date is in existing periods.')
        setOpenWarningDialog(true)
      }
    } else {
      // Handle confirmation

      // Loop through generated periods and print each to console
      generatedPeriods.forEach((period, index) => {
        postWithToken('periods', token!, period)
          .then(() => {
            console.log('Periods Created')
            setOpen(false)
          })
          .catch((error) => {
            console.log('Periods Creation Failed')
            setWarningDialogMessage('Failed to create component')
            setOpenWarningDialog(true)
          })
      })

      refetchPeriods()
      handleClose()
    }
  }

  const handleDateChange = (newValue: dayjs.Dayjs | null) => {
    console.log('Date selection changed')
    let updatedDate: dayjs.Dayjs | null = newValue
    // Make sure the selected date and settings do not fall into the existing periods.
    // This is a simple check when selecting the date. Note this does not insure
    // generated periods do not conflict. It just checks the start date.
    if (newValue) {
      updatedDate = newValue.date(1) // selected value should always be 1st day of month
      if (yearType === 'CALENDAR') {
        // If setting a calendar year period it should start on 1st day of the 1st month
        console.log('setting month')
        updatedDate = updatedDate.month(0)
      }
    }
    if (!checkDateAgainstExistingPeriods(existingPeriods, updatedDate!)) {
      console.log('New Value: ', updatedDate)
      setSelectedDate(updatedDate)
    } else {
      // Show a warning dialog for date conflict
      setWarningDialogMessage('The selected date has a conflict with existing periods.')
      setOpenWarningDialog(true)
    }
    console.log('completed date selection change')
  }

  return (
    <div>
      <Button variant="contained" onClick={handleOpen} disabled={!rootOrganizationID}>
        Open Periods Creation Wizard
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        sx={{
          '& .MuiPaper-root': {
            height: '80vh', // 80% of the viewport height
          },
        }}
      >
        <DialogTitle>{step === 1 ? 'Step 1: Select Parameters' : 'Step 2: Confirm Periods'}</DialogTitle>
        <DialogContent>
          {step === 1 ? (
            <div>
              <Box>
                <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Year Type</InputLabel>
                  <Select value={yearType} label="Year Type" onChange={(e) => setYearType(e.target.value as string)}>
                    <MenuItem value="CALENDAR" selected>
                      Calendar Year
                    </MenuItem>
                    <MenuItem value="FISCAL">Custom Fiscal Year</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <div>
                {yearType === 'CALENDAR' ? (
                  <div>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                      <FormControl variant="standard" fullWidth>
                        <Stack spacing={2} sx={{ flexGrow: 1 }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              label="Select Starting Year"
                              views={['year']}
                              value={selectedDate}
                              onChange={handleDateChange}
                              slotProps={{
                                textField: {
                                  variant: 'standard',
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Stack>
                      </FormControl>
                      <FormControl variant="standard" fullWidth>
                        <InputLabel>Generate How Many Years?</InputLabel>
                        <Select value={length} label="Length" onChange={(e) => setLength(e.target.value as number)}>
                          {[1, 2, 3, 4].map((num) => (
                            <MenuItem key={num} value={num}>
                              {num}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl variant="standard" fullWidth>
                        <InputLabel>Period Breakdown</InputLabel>
                        <Select value={depth} label="Depth" onChange={(e) => setDepth(e.target.value as string)}>
                          <MenuItem value="MONTH" selected>
                            Month
                          </MenuItem>
                          <MenuItem value="QUARTER">Quarter</MenuItem>
                          <MenuItem value="BIANNUAL">Bi-Annual</MenuItem>
                          <MenuItem value="ANNUAL">Annual</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                ) : (
                  <div>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                      <FormControl variant="standard" fullWidth>
                        <Stack spacing={2} sx={{ flexGrow: 1 }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              label="Select Starting Fiscal Year Month"
                              views={['year', 'month']}
                              value={selectedDate}
                              onChange={handleDateChange}
                              slotProps={{
                                textField: {
                                  variant: 'standard',
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </Stack>
                      </FormControl>
                      <FormControl variant="standard" fullWidth>
                        <InputLabel>Generate How Many Fiscal Years?</InputLabel>
                        <Select value={length} label="Length" onChange={(e) => setLength(e.target.value as number)}>
                          {[1, 2, 3, 4].map((num) => (
                            <MenuItem key={num} value={num} selected={num === 1}>
                              {num}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl variant="standard" fullWidth>
                        <InputLabel>Period Breakdown</InputLabel>
                        <Select value={depth} label="Depth" onChange={(e) => setDepth(e.target.value as string)}>
                          <MenuItem value="MONTH" selected>
                            Month
                          </MenuItem>
                          <MenuItem value="QUARTER">Quarter</MenuItem>
                          <MenuItem value="BIANNUAL">Bi-Annual</MenuItem>
                          <MenuItem value="ANNUAL">Annual</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <Box sx={{ mt: 2 }}>
              <PeriodsTable periods={generatedPeriods} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleNext}>{step === 1 ? 'Next' : 'Create Periods'}</Button>
        </DialogActions>
      </Dialog>

      <WarningDialog
        open={openWarningDialog}
        onClose={() => {
          setOpenWarningDialog(false)
        }}
        displayMessage={warningDialogMessage}
      />
    </div>
  )
}

export default PeriodsWizard
