import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import React from 'react'

type AcceleratorSelectorProps = {
  value: string
  setValue: (newValue: string) => void
  incrementAmount: number
  disabled?: boolean
  reviewOnly?: boolean
  fontSize?: string
}

const AcceleratorSelector: React.FC<AcceleratorSelectorProps> = ({
  value,
  setValue,
  incrementAmount,
  disabled = false,
  reviewOnly = false,
  fontSize = '1.1rem',
}) => {
  const adjustMultiplier = (increment: boolean) => {
    const numericPart = parseFloat(value.replace(/[^\d.-]/g, ''))
    let newValue = increment ? numericPart + incrementAmount : numericPart - incrementAmount
    newValue = Math.max(0.01, Math.min(9.99, Math.round(newValue * 100) / 100))
    setValue(newValue.toFixed(2))
  }

  const controls = reviewOnly ? null : (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '2px' }}>
      <IconButton
        size="small"
        onClick={() => adjustMultiplier(true)}
        disabled={disabled || parseFloat(value.replace(/[^\d.-]/g, '')) >= 9.9}
        style={{ padding: '2px' }}
      >
        <ArrowDropUp style={{ fontSize: '18px' }} />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => adjustMultiplier(false)}
        disabled={disabled || parseFloat(value.replace(/[^\d.-]/g, '')) <= 0.1}
        style={{ padding: '2px' }}
      >
        <ArrowDropDown style={{ fontSize: '18px' }} />
      </IconButton>
    </div>
  )

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography color={disabled ? 'text.disabled' : 'text.primary'} style={{ fontSize: fontSize }}>
        {value.replace(/[^\d.-]/g, '')}
      </Typography>
      {controls}
    </div>
  )
}

export default AcceleratorSelector
