import { PaginationInfo } from './common'

// Query Parameters
export interface AccrualsQueryParams {
  organizationIds: string[]
  periodIds: string[]
  status: 'Draft' | 'Pending' | 'Approved' | 'Rejected' | 'Recorded' | 'Cancelled'
  searchTerm?: string
  page?: number
  perPage?: number
}

// Accrual Item (updated to match backend's ExpandedAccrual)
export interface Accrual {
  id: string
  userId: string
  rootOrganizationId: string
  organizationId: string
  planId: string
  planRevision: number
  allocationTableId: string
  allocationTableName: string
  allocationRowId: string
  allocationRowName: string
  rootPeriodId: string
  periodId: string
  status: string
  accelerator: number | null
  achievementRatio: number | null
  payoutAmount: number | null
  payoutOverride: number | null
  formulaUsed: string | null
  approvalBatchId: string | null
  createdAt: string
  updatedAt: string
  userFirstName: string
  userLastName: string
  userLedgerAccountId: string | null
  ledgerAccountLabel: string | null
  finalAchievementRatio: number | null
  effectiveDate: string
  rowType: string
  totalPeriodDays: number
  proratedDays: number
  proratedPayoutAmount: number | null
}

// Successful Response
export interface AccrualsResponse {
  accruals: Accrual[]
  pagination: PaginationInfo
}

// Error Response
export interface ErrorResponse {
  error: string
}

// Helper function to get latest accruals by rowId
export const getLatestAccrualsByRowId = (accruals: Accrual[]): Accrual[] => {
  // Create a map to store the latest accrual for each rowId
  const latestAccrualMap = new Map<string, Accrual>()

  accruals.forEach((accrual) => {
    const existingAccrual = latestAccrualMap.get(accrual.allocationRowId)

    if (!existingAccrual || new Date(accrual.effectiveDate) > new Date(existingAccrual.effectiveDate)) {
      latestAccrualMap.set(accrual.allocationRowId, accrual)
    }
  })

  return Array.from(latestAccrualMap.values())
}
