import useSWR from 'swr'
import { ExpandedCompensationPlan } from 'types/comp-plan'
import { fetchWithToken, fetchWithTokenWithStatus } from './http'

export function usePlan(
  token: string,
  userId: string | undefined,
  params: Record<string, string> | null,
  shouldFetch: boolean = true
) {
  const { data, error, isLoading } = useSWR(
    shouldFetch && userId ? [getUrl(userId, params), token, params] : null,
    ([url, token, params]) => fetchWithToken(url, token, null),
    {
      suspense: true,
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnMount: true,
    }
  )

  const plan = data as ExpandedCompensationPlan | undefined
  const notFound = !plan || !plan.plan || (data && 'error' in data && data.error === 'CompensationPlan not found')

  return {
    compPlan: notFound ? undefined : plan,
    isLoading,
    isError: error || (data && 'error' in data), // Consider both SWR error and API error
    notFound,
    errorMessage: (error as Error)?.message || (data && 'error' in data ? data.error : undefined),
  }
}

function getUrl(userId: string, params: Record<string, string> | null): string {
  if (params && params.planId && params.revision) {
    return `compensation/plans/user/${userId}/${params.planId}/${params.revision}`
  }
  return `compensation/plans/user/${userId}/latest`
}

export function usePlanWithStatus(
  token: string,
  userId: string | undefined,
  params: Record<string, string> | null,
  shouldFetch: boolean = true
) {
  const { data, error, isLoading } = useSWR(
    shouldFetch && userId ? [getUrl(userId, params), token, params] : null,
    ([url, token, params]) => fetchWithTokenWithStatus(url, token, null),
    {
      shouldRetryOnError: false,
    }
  )

  const { data: responseData, status } = data || { data: undefined, status: undefined }
  const plan = responseData as ExpandedCompensationPlan | undefined
  const notFound =
    !plan ||
    !plan.plan ||
    (responseData && 'error' in responseData && responseData.error === 'CompensationPlan not found')

  return {
    compPlan: notFound ? undefined : plan,
    isLoading,
    isError: error || (data && 'error' in data), // Consider both SWR error and API error
    notFound,
    errorMessage: (error as Error)?.message || (data && 'error' in data ? data.error : undefined),
    status: status || 0,
  }
}
