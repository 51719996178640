import { SearchOutlined } from '@ant-design/icons'
import { InputAdornment, TextField } from '@mui/material'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

interface SearchInputProps {
  key?: string
  onSearch: (value: string) => void
  initialValue?: string
  debounceTime?: number
}

const SearchInput: React.FC<SearchInputProps> = ({ key = null, onSearch, initialValue = '', debounceTime = 300 }) => {
  const [inputValue, setInputValue] = useState(initialValue)

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      onSearch(value)
    }, debounceTime),
    [onSearch, debounceTime]
  )

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      setInputValue(value)
      debouncedSearch(value)
    },
    [debouncedSearch]
  )

  useEffect(() => {
    setInputValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
    }
  }, [debouncedSearch])

  return (
    <TextField
      key={key}
      label="Search"
      placeholder="enter search"
      value={inputValue}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined />
          </InputAdornment>
        ),
      }}
      sx={{
        userSelect: 'none',
        width: '100%',
        '& .MuiInputLabel-root': {
          backgroundColor: 'transparent',
        },
      }}
      variant="standard"
      onChange={handleSearchChange}
    />
  )
}

export default React.memo(SearchInput)
