import { ComponentType } from 'types/components'
import { WizardConfiguration } from 'types/wizard-config'
import { RowType } from 'types/comp-allocations'

export const wizardConfigData: WizardConfiguration[] = [
  {
    id: '1',
    label: 'Services Company',
    description: 'Professional Services Company Default Components',
    targets: [
      {
        id: '1',
        name: 'Revenue',
        description: 'Company Revenue for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_CURRENCY,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0,
        maxTargetValue: 10000000,
      },
      {
        id: '2',
        name: 'Gross Profit',
        description: 'Gross Profit for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_CURRENCY,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0,
        maxTargetValue: 10000000,
      },
      {
        id: '3',
        name: 'Gross Profit Margin',
        description: 'Gross Profit Margin for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_PERCENTAGE,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0.0,
        maxTargetValue: 1.0,
      },
      {
        id: '4',
        name: 'EBITDA Target',
        description: 'EBITDA for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_CURRENCY,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0,
        maxTargetValue: 10000000,
      },
      {
        id: '5',
        name: 'Net Income',
        description: 'Net Income for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_CURRENCY,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0,
        maxTargetValue: 10000000,
      },
      {
        id: '6',
        name: 'Revenue Growth',
        description: 'Revenue Growth for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_PERCENTAGE,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0.0,
        maxTargetValue: 1.0,
      },
    ],
    templates: [
      {
        id: '1',
        label: 'Services - CEO, President, or Managing Director',
        description: 'Template 1 Description',
        name: 'Leadership',
        position: '',
        minVariableCompensation: 0.1,
        maxVariableCompensation: 0.5,
        defaultVariableCompensation: 0.3,
        minMaxPayout: 1.2,
        maxMaxPayout: 2.0,
        defaultMaxPayout: 1.5,
        basePlus: true,
        targets: ['1', '2', '3'],
        componentSetup: [
          {
            id: '1',
            label: 'Primary',
            description: 'Primary Components for the template',
            components: [
              {
                id: '1',
                targetId: '1',
                label: 'Revenue',
                description: 'Revenue for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.35,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '2',
                targetId: '2',
                label: 'Gross Profit',
                description: 'Gross Profit for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.35,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
            ],
          },
          {
            id: '2',
            label: 'Secondary',
            description: 'Secondary Components for the template',
            components: [
              {
                id: '3',
                targetId: '3',
                label: 'Gross Profit Margin',
                description: 'Gross Profit Margin for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
            ],
          },
        ],
      },
      {
        id: '2',
        label: 'Manager',
        description: 'Template 2 Description',
        name: 'Manager',
        position: '',
        minVariableCompensation: 0.1,
        maxVariableCompensation: 0.5,
        defaultVariableCompensation: 0.3,
        minMaxPayout: 1.2,
        maxMaxPayout: 2.0,
        defaultMaxPayout: 1.5,
        basePlus: true,
        targets: ['4', '5', '6'],
        componentSetup: [
          {
            id: '1',
            label: 'General',
            description: 'General Components for the template',
            components: [
              {
                id: '1',
                targetId: '4',
                label: 'EBITDA',
                description: 'EBITDA for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '2',
                targetId: '5',
                label: 'Net Income',
                description: 'Net Income for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '3',
                targetId: '6',
                label: 'Revenue Growth',
                description: 'Revenue Growth for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
            ],
          },
        ],
      },
      {
        id: '3',
        label: 'Sales Person',
        description: 'Template 3 Description',
        name: 'Sales Person',
        position: '',
        minVariableCompensation: 0.1,
        maxVariableCompensation: 0.5,
        defaultVariableCompensation: 0.3,
        minMaxPayout: 1.2,
        maxMaxPayout: 2.0,
        defaultMaxPayout: 1.5,
        basePlus: true,
        targets: ['1', '2', '3', '4', '5', '6'],
        componentSetup: [
          {
            id: '1',
            label: 'General',
            description: 'General Components for the template',
            components: [
              {
                id: '1',
                targetId: '1',
                label: 'Revenue',
                description: 'Revenue for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '2',
                targetId: '2',
                label: 'Gross Profit',
                description: 'Gross Profit for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '3',
                targetId: '3',
                label: 'Gross Profit Margin',
                description: 'Gross Profit Margin for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '4',
                targetId: '4',
                label: 'Net Income',
                description: 'Net Income for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '5',
                targetId: '5',
                label: 'Revenue Growth',
                description: 'Revenue Growth for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '6',
                targetId: '6',
                label: 'Revenue Growth',
                description: 'Revenue Growth for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
            ],
          },
        ],
      },
    ],
    plans: [],
  },
  {
    id: '2',
    label: 'SaaS Company',
    description: 'SaaS Company Default Components',
    targets: [
      {
        id: '1',
        name: 'Revenue',
        description: 'Company Revenue for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_CURRENCY,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0,
        maxTargetValue: 10000000,
      },
      {
        id: '2',
        name: 'Gross Profit',
        description: 'Gross Profit for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_CURRENCY,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0,
        maxTargetValue: 10000000,
      },
      {
        id: '3',
        name: 'Gross Profit Margin',
        description: 'Gross Profit Margin for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_PERCENTAGE,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0.0,
        maxTargetValue: 1.0,
      },
      {
        id: '4',
        name: 'EBITDA Target',
        description: 'EBITDA for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_CURRENCY,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0,
        maxTargetValue: 10000000,
      },
      {
        id: '5',
        name: 'Net Income',
        description: 'Net Income for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_CURRENCY,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0,
        maxTargetValue: 10000000,
      },
      {
        id: '6',
        name: 'Revenue Growth',
        description: 'Revenue Growth for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_PERCENTAGE,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0.0,
        maxTargetValue: 1.0,
      },
      {
        id: '7',
        name: 'LTV',
        description: 'LTV for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_CURRENCY,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0.0,
        maxTargetValue: 10000000,
      },
      {
        id: '8',
        name: 'CAC',
        description: 'CAC for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_CURRENCY,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0.0,
        maxTargetValue: 10000000,
      },
      {
        id: '9',
        name: 'Churn Rate',
        description: 'Churn Rate for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_PERCENTAGE,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0.0,
        maxTargetValue: 1.0,
      },
    ],
    templates: [
      {
        id: '1',
        label: 'SaaS - CEO, President, CFO, COO, or Managing Director',
        description: 'Template 1 Description',
        name: 'Leadership',
        position: '',
        minVariableCompensation: 0.1,
        maxVariableCompensation: 0.5,
        defaultVariableCompensation: 0.3,
        minMaxPayout: 1.2,
        maxMaxPayout: 2.0,
        defaultMaxPayout: 1.5,
        basePlus: true,
        targets: ['1', '2', '3'],
        componentSetup: [
          {
            id: '1',
            label: 'Primary Section',
            description: 'Primary Components for the template',
            components: [
              {
                id: '1',
                targetId: '1',
                label: 'Revenue',
                description: 'Revenue for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '2',
                targetId: '2',
                label: 'Gross Profit',
                description: 'Gross Profit for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
            ],
          },
          {
            id: '2',
            label: 'Secondary Section',
            description: 'Secondary Components for the template',
            components: [
              {
                id: '1',
                targetId: '3',
                label: 'Gross Profit Margin',
                description: 'Gross Profit Margin for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
            ],
          },
        ],
      },
      {
        id: '2',
        label: 'Product Manager',
        description: 'Template 2 Description',
        name: 'Product Manager',
        position: '',
        minVariableCompensation: 0.1,
        maxVariableCompensation: 0.5,
        defaultVariableCompensation: 0.3,
        minMaxPayout: 1.2,
        maxMaxPayout: 2.0,
        defaultMaxPayout: 1.5,
        basePlus: true,
        targets: ['4', '5', '6', '7', '8'],
        componentSetup: [
          {
            id: '1',
            label: 'General',
            description: 'General Components for the template',
            components: [
              {
                id: '1',
                targetId: '4',
                label: 'EBITDA',
                description: 'EBITDA for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '2',
                targetId: '5',
                label: 'Net Income',
                description: 'Net Income for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '3',
                targetId: '6',
                label: 'Revenue Growth',
                description: 'Revenue Growth for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '4',
                targetId: '7',
                label: 'LTV',
                description: 'LTV for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '5',
                targetId: '8',
                label: 'CAC',
                description: 'CAC for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
            ],
          },
        ],
      },
      {
        id: '3',
        label: 'Engineering Manager',
        description: 'Template 3 Description',
        name: 'Engineering Manager',
        position: '',
        minVariableCompensation: 0.1,
        maxVariableCompensation: 0.5,
        defaultVariableCompensation: 0.3,
        minMaxPayout: 1.2,
        maxMaxPayout: 2.0,
        defaultMaxPayout: 1.5,
        basePlus: true,
        targets: ['1', '2', '3', '4', '5', '6'],
        componentSetup: [
          {
            id: '1',
            label: 'General',
            description: 'General Components for the template',
            components: [
              {
                id: '1',
                targetId: '1',
                label: 'Revenue',
                description: 'Revenue for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '2',
                targetId: '2',
                label: 'Gross Profit',
                description: 'Gross Profit for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '3',
                targetId: '3',
                label: 'Gross Profit Margin',
                description: 'Gross Profit Margin for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '4',
                targetId: '4',
                label: 'Net Income',
                description: 'Net Income for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '5',
                targetId: '5',
                label: 'Revenue Growth',
                description: 'Revenue Growth for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '6',
                targetId: '6',
                label: 'Gross Profit Margin',
                description: 'Gross Profit Margin for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
            ],
          },
        ],
      },
    ],
    plans: [],
  },
  {
    id: '3',
    label: 'Testing Company',
    description: 'Testing Company Default Components',
    targets: [
      {
        id: '1',
        name: 'Revenue',
        description: 'Company Revenue for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_CURRENCY,
        proration: 'Prorated',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0,
        maxTargetValue: 10000000,
      },
    ],
    templates: [
      {
        id: '1',
        label: 'Testing - CEO, President, CFO, COO, or Managing Director',
        description: 'Testing - Template 1 Description',
        name: 'Leadership',
        position: '',
        minVariableCompensation: 0.1,
        maxVariableCompensation: 0.5,
        defaultVariableCompensation: 0.3,
        minMaxPayout: 1.2,
        maxMaxPayout: 2.0,
        defaultMaxPayout: 1.5,
        basePlus: true,
        targets: ['1'],
        componentSetup: [
          {
            id: '1',
            label: 'Primary Section',
            description: 'Primary Components for the template',
            components: [
              {
                id: '1',
                targetId: '1',
                label: 'Revenue',
                description: 'Revenue for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
            ],
          },
        ],
      },
    ],
    plans: [],
  },
  {
    id: '4',
    label: 'Multi-Industry - Executive Comp',
    description: 'Multi Industry - Executive Compensation Defaults',
    targets: [
      {
        id: '1',
        name: 'Revenue',
        description: 'Company Revenue for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_CURRENCY,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0,
        maxTargetValue: 10000000,
      },
      {
        id: '2',
        name: 'Gross Profit',
        description: 'Gross Profit for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_CURRENCY,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0,
        maxTargetValue: 10000000,
      },
      {
        id: '3',
        name: 'Gross Profit Margin',
        description: 'Gross Profit Margin for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_PERCENTAGE,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0.0,
        maxTargetValue: 1.0,
      },
      {
        id: '4',
        name: 'EBITDA Target',
        description: 'EBITDA for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_CURRENCY,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0,
        maxTargetValue: 10000000,
      },
      {
        id: '5',
        name: 'Net Income',
        description: 'Net Income for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_CURRENCY,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0,
        maxTargetValue: 10000000,
      },
      {
        id: '6',
        name: 'Revenue Growth',
        description: 'Revenue Growth for the period',
        type: 'ORGANIZATION',
        format: ComponentType.COMPONENT_TYPE_PERCENTAGE,
        proration: 'None',
        value: 0.0,
        restrictedVisibility: false,
        minTargetValue: 0.0,
        maxTargetValue: 1.0,
      },
    ],
    templates: [
      {
        id: '1',
        label: 'Services - CEO, President, or Managing Director',
        description: 'Template 1 Description',
        name: 'Leadership',
        position: '',
        minVariableCompensation: 0.1,
        maxVariableCompensation: 0.5,
        defaultVariableCompensation: 0.3,
        minMaxPayout: 1.2,
        maxMaxPayout: 2.0,
        defaultMaxPayout: 1.5,
        basePlus: true,
        targets: ['1', '2', '3'],
        componentSetup: [
          {
            id: '1',
            label: 'Primary',
            description: 'Primary Components for the template',
            components: [
              {
                id: '1',
                targetId: '1',
                label: 'Revenue',
                description: 'Revenue for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.35,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '2',
                targetId: '2',
                label: 'Gross Profit',
                description: 'Gross Profit for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.35,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
            ],
          },
          {
            id: '2',
            label: 'Secondary',
            description: 'Secondary Components for the template',
            components: [
              {
                id: '3',
                targetId: '3',
                label: 'Gross Profit Margin',
                description: 'Gross Profit Margin for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
            ],
          },
        ],
      },
      {
        id: '2',
        label: 'Manager',
        description: 'Template 2 Description',
        name: 'Manager',
        position: '',
        minVariableCompensation: 0.1,
        maxVariableCompensation: 0.5,
        defaultVariableCompensation: 0.3,
        minMaxPayout: 1.2,
        maxMaxPayout: 2.0,
        defaultMaxPayout: 1.5,
        basePlus: true,
        targets: ['4', '5', '6'],
        componentSetup: [
          {
            id: '1',
            label: 'General',
            description: 'General Components for the template',
            components: [
              {
                id: '1',
                targetId: '4',
                label: 'EBITDA',
                description: 'EBITDA for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '2',
                targetId: '5',
                label: 'Net Income',
                description: 'Net Income for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '3',
                targetId: '6',
                label: 'Revenue Growth',
                description: 'Revenue Growth for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
            ],
          },
        ],
      },
      {
        id: '3',
        label: 'Sales Person',
        description: 'Template 3 Description',
        name: 'Sales Person',
        position: '',
        minVariableCompensation: 0.1,
        maxVariableCompensation: 0.5,
        defaultVariableCompensation: 0.3,
        minMaxPayout: 1.2,
        maxMaxPayout: 2.0,
        defaultMaxPayout: 1.5,
        basePlus: true,
        targets: ['1', '2', '3', '4', '5', '6'],
        componentSetup: [
          {
            id: '1',
            label: 'General',
            description: 'General Components for the template',
            components: [
              {
                id: '1',
                targetId: '1',
                label: 'Revenue',
                description: 'Revenue for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '2',
                targetId: '2',
                label: 'Gross Profit',
                description: 'Gross Profit for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '3',
                targetId: '3',
                label: 'Gross Profit Margin',
                description: 'Gross Profit Margin for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '4',
                targetId: '4',
                label: 'Net Income',
                description: 'Net Income for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '5',
                targetId: '5',
                label: 'Revenue Growth',
                description: 'Revenue Growth for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
              {
                id: '6',
                targetId: '6',
                label: 'Revenue Growth',
                description: 'Revenue Growth for the period',
                componentType: RowType.CALCULATION_ROW,
                defaultPeriod: 'CURRENT_ROOT',
                hurdleValue: 0,
                minAllocationPercentage: 0.2,
                maxAllocationPercentage: 0.5,
                defaultAllocationPercentage: 0.3,
                minPayoutPercentage: 1.0,
                maxPayoutPercentage: 1.5,
                defaultPayoutPercentage: 1.3,
                defaultAccelerator: 1.2,
              },
            ],
          },
        ],
      },
    ],
    plans: [],
  },
]
