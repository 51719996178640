// material-ui
import { Stack, Typography } from '@mui/material'

const Footer = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '0px 16px 0px', mt: 2 }}>
    <Typography variant="caption">&copy; 2024 Purcent LLC - A Boost Phase Venture. All rights reserved</Typography>
    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center"></Stack>
  </Stack>
)

export default Footer
