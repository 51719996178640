import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import AnimateButton from 'components/@extended/AnimateButton'
import ComponentField from 'components/ComponentField'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import TrueFalseSelector from 'components/TrueFalseSelector'
import { useGlobalState } from 'contexts/GlobalStateContext'
import { postWithToken } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import { useGetRootOrganizationComponentLabelsWithStatus } from 'hooks/useComponents'
import { useFetchOrganizationCoreSettings } from 'hooks/useOrganizations'
import { usePeriods } from 'hooks/usePeriods'
import { useProfile } from 'hooks/useProfile'
import { useSnackbar } from 'notistack'
import { FC, startTransition, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ComponentType, CreateTargetDTO, ProrationMode } from 'types/components'
import { ThemeMode } from 'types/config'
import { OrganizationCoreSetting } from 'types/organization'
import { createIdLabelMap, createPeriodToRootPeriodMap, Period } from 'types/periods'
import { getOrganizationLabelMap } from 'types/user-profile'

interface TargetMeta {
  targetName: string
  orgLevel: boolean
  targetMin: number | null
  targetMax: number | null
  targetDefault: number | boolean | null
  targetFormat: ComponentType
  isExpanded: boolean
  restrictedVisibility: boolean
  prorationMode: ProrationMode
}

const CreateTargets: FC = () => {
  let generatedTargets: CreateTargetDTO[] = []
  const theme = useTheme()
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([])
  const [targetMetas, setTargetMetas] = useState<TargetMeta[]>([
    {
      targetName: '',
      orgLevel: true,
      targetMin: null,
      targetMax: null,
      targetDefault: null,
      targetFormat: 'int' as ComponentType,
      isExpanded: false,
      restrictedVisibility: false,
      prorationMode: ProrationMode.PRORATION_MODE_NONE,
    },
  ])
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const [isReviewMode, setIsReviewMode] = useState(false)
  const [coreSettingsMap, setCoreSettingsMap] = useState<Record<string, OrganizationCoreSetting>>({})
  const [isReviewLoading, setIsReviewLoading] = useState(false)
  const [isReviewClicked, setIsReviewClicked] = useState(false)
  const [reviewTargets, setReviewTargets] = useState<CreateTargetDTO[]>([])
  const [availableLabels, setAvailableLabels] = useState<string[]>(['Revenue', 'Cost', 'Profit'])

  const { token } = useAuth()
  const { purcentAppState } = useGlobalState()
  const { profile, isLoading: isLoadingProfile } = useProfile(token!)
  const { periods, isLoadingPeriods } = usePeriods(
    token!,
    purcentAppState?.selectedOrganization?.rootOrganizationId || ''
  )
  const { coreSettings, isLoading: isLoadingCoreSettings } = useFetchOrganizationCoreSettings(
    selectedOrganizations,
    token!
  )

  const { labels, isLoading: isLoadingLabels } = useGetRootOrganizationComponentLabelsWithStatus(
    token!,
    purcentAppState?.selectedOrganization?.rootOrganizationId || '',
    true
  )

  const hoverBackgroundColor =
    theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.secondary.dark

  const resetState = () => {
    setIsReviewClicked(false)
    setIsReviewLoading(false)
  }

  useEffect(() => {
    const handlePopState = () => {
      resetState() // Reset the state when the back button is pressed
    }

    // Add event listener to listen to the popstate event (back button)
    window.addEventListener('popstate', handlePopState)

    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  useEffect(() => {
    if (labels) {
      setAvailableLabels(labels)
    }
  }, [labels])

  useEffect(() => {
    console.log('isReviewLoading:', isReviewLoading)
  }, [isReviewLoading])

  const periodLabelMap =
    periods && periods.periods
      ? periods.periods.reduce((acc: { [key: string]: string }, period) => {
          const idLabelMap = createIdLabelMap(period)
          Object.entries(idLabelMap).forEach(([id, label]) => {
            const newLabel = label.includes(period.label) ? label : `${label} (${period.label})`
            acc[id] = newLabel
          })
          return acc
        }, {})
      : {}

  const rootPeriodMap = periods && periods.periods ? createPeriodToRootPeriodMap(periods.periods) : {}

  const organizationLabelMap = getOrganizationLabelMap(profile)

  useEffect(() => {
    // TODO: Add logic to handle when profile is not loaded
  }, [profile, selectedOrganizations, organizationLabelMap])

  useEffect(() => {
    if (coreSettings && coreSettings.length > 0) {
      const newCoreSettingsMap = coreSettings.reduce(
        (acc, setting) => {
          acc[setting.organizationId] = setting
          return acc
        },
        {} as Record<string, OrganizationCoreSetting>
      )
      setCoreSettingsMap(newCoreSettingsMap)
    }
  }, [coreSettings])

  const handleMetricChange = (index: number, field: string, value: any) => {
    const newMetrics = [...targetMetas]
    newMetrics[index] = { ...newMetrics[index], [field]: value }
    setTargetMetas(newMetrics)
  }

  const handleProrationModeChange = (index: number, value: ProrationMode) => {
    const newMetrics = [...targetMetas]
    newMetrics[index].prorationMode = value
    setTargetMetas(newMetrics)
  }

  const addMetric = () => {
    setTargetMetas([
      ...targetMetas,
      {
        targetName: '',
        orgLevel: true,
        targetMin: null,
        targetMax: null,
        targetDefault: null,
        targetFormat: 'int' as ComponentType,
        isExpanded: false,
        restrictedVisibility: false,
        prorationMode: ProrationMode.PRORATION_MODE_NONE,
      },
    ])
  }

  const handleOrganizationChange = (newOrganizations: string[]) => {
    startTransition(() => {
      setSelectedOrganizations(newOrganizations)
    })
  }

  const handlePeriodChange = (newPeriods: string[]) => {
    startTransition(() => {
      setSelectedPeriods(newPeriods)
    })
  }

  const removeMetric = (index: number) => {
    setTargetMetas(targetMetas.filter((_, i) => i !== index))
  }

  const handleReview = () => {
    setIsReviewClicked(true)
    setIsReviewLoading(true)

    // Make sure the the component creation is after the next frame refresh
    // in order to give the UI time to update.
    setTimeout(() => {
      createNewReviewTargetsV2()
    }, 0)
  }

  const generateTargetsForOrganization = (rootOrgId: string, orgId: string, rootPeriod: Period, span: number) => {
    const processPeriods = (period: Period, span: number) => {
      targetMetas.forEach((target) => {
        let calculatedPeriodValue: number = 0
        if (target.targetFormat !== ComponentType.COMPONENT_TYPE_BOOL) {
          calculatedPeriodValue = parseFloat(target.targetDefault?.toString() ?? '0')
        }

        console.log(calculatedPeriodValue)

        if (
          target.prorationMode === ProrationMode.PRORATION_MODE_AMORTIZE ||
          target.prorationMode === ProrationMode.PRORATION_MODE_CUMULATIVE
        ) {
          calculatedPeriodValue /= span
        }

        const rootPeriodId = rootPeriodMap[period.id!]

        const temp: CreateTargetDTO = {
          rootOrganizationId: rootOrgId,
          organizationId: orgId,
          rootPeriodId: rootPeriodId,
          periodId: period.id!,
          label: target.targetName,
          isOrganizationLevel: target.orgLevel,
          isRestricted: target.restrictedVisibility,
          componentType: target.targetFormat,
          targetMin: target.targetMin?.toString() ?? '',
          targetMax: target.targetMax?.toString() ?? '',
          value:
            target.targetFormat !== ComponentType.COMPONENT_TYPE_BOOL
              ? calculatedPeriodValue.toString()
              : target.targetDefault?.toString() ?? '',
        }
        console.log('New Target', { 'temp target': temp })
        generatedTargets.push(temp)
      })

      if (period.children) {
        period.children.forEach((child) => {
          processPeriods(child, span * period.children.length)
        })
      }
    }

    processPeriods(rootPeriod, span)
    setReviewTargets(generatedTargets)
    setIsReviewMode(true)
    setIsReviewLoading(false)
  }

  const getSelectedRootPeriods = (periodIds: string[]): Period[] => {
    const _periods: Period[] = []

    const findPeriodById = (period: Period, id: string): Period | null => {
      if (period.id === id) {
        return period
      }

      if (period.children) {
        for (const child of period.children) {
          const found = findPeriodById(child, id)
          if (found) {
            return found
          }
        }
      }

      return null
    }

    const isChildOf = (parent: Period, childId: string): boolean => {
      if (!parent.children) {
        return false
      }

      for (const child of parent.children) {
        if (child.id === childId) {
          return true
        }

        if (isChildOf(child, childId)) {
          return true
        }
      }
      return false
    }

    periodIds.forEach((id) => {
      if (periods && periods.periods) {
        for (const rootPeriod of periods.periods) {
          const foundPeriod = findPeriodById(rootPeriod, id)
          if (foundPeriod) {
            // Ignore if the found Period is not a root period
            // Skip if foundPeriod is a child of any already selected periods
            const isChildOfSelectedPeriods = _periods.some((selectedPeriod) =>
              isChildOf(selectedPeriod, foundPeriod.id!)
            )
            if (isChildOfSelectedPeriods) {
              continue
            }

            _periods.push(foundPeriod)
            break
          }
        }
      }
    })

    return _periods
  }

  const createNewReviewTargetsV2 = () => {
    generatedTargets = []
    const _periods = getSelectedRootPeriods(selectedPeriods)

    selectedOrganizations.forEach((orgId) => {
      _periods.forEach((_period) => {
        generateTargetsForOrganization(
          purcentAppState?.selectedOrganization?.rootOrganizationId || '',
          orgId,
          _period,
          _periods.length
        )
      })
    })

    setReviewTargets(generatedTargets)
    setIsReviewMode(true)
    setIsReviewLoading(false)
  }

  const handleTargetChange = (index: number, value: string) => {
    const updatedTargets = [...reviewTargets]
    updatedTargets[index].value = value
    setReviewTargets(updatedTargets)
  }

  const handleCreateTargets = () => {
    postWithToken('components/targets', token!, { targets: reviewTargets })
      .then((response) => {
        enqueueSnackbar('Targets created successfully', { variant: 'success' })
        // Reset the form
        setIsReviewMode(false)
        setSelectedOrganizations([])
        setSelectedPeriods([])
        setTargetMetas([
          {
            targetName: '',
            orgLevel: true,
            targetMin: null,
            targetMax: null,
            targetDefault: null,
            targetFormat: 'int' as ComponentType,
            isExpanded: false,
            restrictedVisibility: false,
            prorationMode: ProrationMode.PRORATION_MODE_NONE,
          },
        ])
      })
      .catch((error) => {
        const errorMessage = error.message || 'Failed to create targets'
        enqueueSnackbar(errorMessage, { variant: 'error' })
      })
      .finally(() => {
        // Redirect to /admin/components/targets
        startTransition(() => {
          navigate('/admin/components/targets')
        })
      })
  }

  const renderOrgPeriodConfig = () => {
    return (
      <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
        <Typography variant="h4" gutterBottom>
          Select Teams & Periods for Targets
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {isLoadingProfile ? (
              <CircularProgress />
            ) : (
              <MultiOrganizationSelector
                userProfile={profile}
                selectedOrganizationIds={selectedOrganizations}
                handleChange={handleOrganizationChange}
                label="Select Teams"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {isLoadingPeriods ? (
              <CircularProgress />
            ) : (
              <MultiPeriodSelector
                periods={periods.periods}
                selectedPeriods={selectedPeriods}
                handleChange={handlePeriodChange}
                label="Select Periods"
                disableMultiRootSelection={true}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    )
  }

  const handleExpand = (index: number) => {
    const newMetrics = [...targetMetas]
    newMetrics[index].isExpanded = !newMetrics[index].isExpanded
    setTargetMetas(newMetrics)
  }

  const checkTargetMetasValid = () => {
    return targetMetas.every(
      (targetMeta) =>
        targetMeta.targetName !== '' && targetMeta.targetName !== null && targetMeta.targetDefault !== null
    )
  }

  const renderTargetMetaConfig = () => {
    return (
      <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
        <Typography variant="h4" gutterBottom>
          Targets
        </Typography>
        <Grid container spacing={3}>
          {targetMetas.map((targetMeta, index) => (
            <Grid container item xs={12} spacing={2} key={index}>
              <Grid item xs={12} sm={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IconButton onClick={() => handleExpand(index)}>
                  {targetMeta.isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={2}>
                {isLoadingLabels ? (
                  <CircularProgress />
                ) : (
                  <Autocomplete
                    freeSolo
                    options={availableLabels}
                    value={targetMeta.targetName}
                    onInputChange={(_, newValue) => handleMetricChange(index, 'targetName', newValue || '')}
                    renderInput={(params) => <TextField {...params} label="Name" variant="standard" fullWidth />}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={1}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={targetMeta.orgLevel}
                    onChange={(e) => handleMetricChange(index, 'metricType', e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .MuiMenuItem-root:hover': {
                            backgroundColor: hoverBackgroundColor,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="true">Team</MenuItem>
                    <MenuItem value="false">Individual</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Format</InputLabel>
                  <Select
                    value={targetMeta.targetFormat}
                    onChange={(e) => handleMetricChange(index, 'targetFormat', e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .MuiMenuItem-root:hover': {
                            backgroundColor: hoverBackgroundColor,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={ComponentType.COMPONENT_TYPE_INTEGER}>Integer</MenuItem>
                    <MenuItem value={ComponentType.COMPONENT_TYPE_CURRENCY}>Currency</MenuItem>
                    <MenuItem value={ComponentType.COMPONENT_TYPE_PERCENTAGE}>Percentage</MenuItem>
                    <MenuItem value={ComponentType.COMPONENT_TYPE_BOOL}>Boolean</MenuItem>
                    <MenuItem value={ComponentType.COMPONENT_TYPE_FLOAT}>Float</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Proration Mode</InputLabel>
                  <Select
                    key={`proration-mode-${index}`}
                    value={
                      targetMeta.targetFormat === ComponentType.COMPONENT_TYPE_BOOL
                        ? ProrationMode.PRORATION_MODE_NONE
                        : targetMeta.prorationMode
                    }
                    variant="standard"
                    onChange={(e) => handleProrationModeChange(index, e.target.value as ProrationMode)}
                    fullWidth
                    disabled={targetMeta.targetFormat === ComponentType.COMPONENT_TYPE_BOOL}
                  >
                    <MenuItem value={ProrationMode.PRORATION_MODE_NONE}>None</MenuItem>
                    <MenuItem value={ProrationMode.PRORATION_MODE_AMORTIZE}>Amortize</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                {renderInputField(index, 'targetDefault', 'Value')}
              </Grid>
              <Grid item xs={12} sm={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IconButton onClick={() => removeMetric(index)}>
                  <DeleteOutlined />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Collapse in={targetMeta.isExpanded}>
                  <Paper elevation={0} style={{ padding: '16px', backgroundColor: theme.palette.grey[100] }}>
                    <Typography variant="h6" gutterBottom>
                      Advanced Settings (when creating new components)
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <TrueFalseSelector
                          name={`restricted-visibility-${index}`}
                          label="Restricted Visibility"
                          value={targetMeta.restrictedVisibility}
                          onChange={(value) => handleMetricChange(index, 'restrictedVisibility', value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        {renderInputField(index, 'targetMin', 'Target Min')}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        {renderInputField(index, 'targetMax', 'Target Max')}
                      </Grid>
                    </Grid>
                  </Paper>
                </Collapse>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <IconButton color="primary" onClick={addMetric} style={{ width: '250px' }}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <PlusOutlined style={{ marginRight: 8 }} />
                {targetMetas.length > 0 ? 'Add Another Target' : 'Add Target'}
              </Box>
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  const renderInputField = (index: number, field: string, label: string) => {
    const targetMeta = targetMetas[index]
    const value = targetMeta[field as keyof typeof targetMeta] ?? ''
    const handleChange = (newValue: any) => handleMetricChange(index, field, newValue)

    return (
      <ComponentField
        componentType={targetMeta.targetFormat as ComponentType}
        label={label}
        value={value}
        onChange={handleChange}
        name={`target-${index}-${field}`}
        currency={coreSettingsMap[selectedOrganizations[0]]?.currency || 'USD'}
        currencyDecimals={coreSettingsMap[selectedOrganizations[0]]?.currencyDecimals || 0}
        decimalPlaces={2}
      />
    )
  }

  const handleBack = () => {
    setIsReviewMode(false)
    setIsReviewClicked(false)
  }

  const renderReviewConfig = () => {
    if (isLoadingCoreSettings) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      )
    }

    const columns = [
      { id: 'organization', label: 'Organization', minWidth: 170 },
      { id: 'period', label: 'Period', minWidth: 170 },
      { id: 'metric', label: 'Name', minWidth: 170 },
      { id: 'type', label: 'Type', minWidth: 100 },
      { id: 'value', label: 'Value', minWidth: 100 },
      { id: 'actions', label: 'Actions', minWidth: 50 },
    ]

    const handleRemoveReviewTarget = (index: number) => {
      const updatedTargets = reviewTargets.filter((_, i) => i !== index)
      setReviewTargets(updatedTargets)
    }

    console.log(reviewTargets)

    return (
      <>
        <Typography variant="h4" gutterBottom>
          Review Targets
        </Typography>
        <Paper sx={{ mb: 3 }}>
          <TableContainer>
            <Table stickyHeader size="small" aria-label="sticky table">
              <TableHead
                sx={{
                  '& th': {
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `2px solid ${theme.palette.divider} !important`,
                  },
                }}
              >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reviewTargets.map((target, index) => {
                  const orgSettings = coreSettingsMap[target.organizationId] || {
                    currency: 'USD',
                    currencyDecimals: 0,
                    percentageDecimals: 2,
                  }
                  const targetMeta = targetMetas.find((meta) => meta.targetName === target.label)
                  return (
                    <TableRow
                      id={'table-row-' + index}
                      sx={{ py: 3, '&:hover': { backgroundColor: hoverBackgroundColor } }}
                      role="checkbox"
                      tabIndex={-1}
                      key={'table-row-' + index}
                    >
                      <TableCell>{organizationLabelMap[target.organizationId]}</TableCell>
                      <TableCell>{periodLabelMap[target.periodId]}</TableCell>
                      <TableCell>{target.label}</TableCell>
                      <TableCell>{target.isOrganizationLevel ? 'Individual' : 'Team'}</TableCell>
                      <TableCell>
                        <ComponentField
                          componentType={targetMeta?.targetFormat as ComponentType}
                          label=""
                          value={target.value}
                          onChange={(value) => handleTargetChange(index, value)}
                          name={`review-target-${index}`}
                          currency={orgSettings.currency}
                          currencyDecimals={orgSettings.currencyDecimals}
                          decimalPlaces={2}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleRemoveReviewTarget(index)}>
                          <DeleteOutlined />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    )
  }

  return (
    <>
      {!isReviewMode && (
        <Typography variant="h3" gutterBottom>
          Configure Targets
        </Typography>
      )}
      {!isReviewMode && renderOrgPeriodConfig()}
      {!isReviewMode && renderTargetMetaConfig()}
      {!isReviewMode ? (
        <Box sx={{ mb: 3 }}>
          <Stack direction="row" justifyContent={'space-between'} spacing={2}>
            <AnimateButton>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  startTransition(() => {
                    navigate('/admin/components/targets')
                  })
                }}
                disabled={isReviewLoading || isReviewClicked}
              >
                Cancel
              </Button>
            </AnimateButton>
            <AnimateButton>
              <Button
                id="review-targets-button"
                variant="contained"
                onClick={handleReview}
                disabled={
                  isLoadingCoreSettings ||
                  isReviewLoading ||
                  isReviewClicked ||
                  selectedOrganizations.length === 0 ||
                  selectedPeriods.length === 0 ||
                  !checkTargetMetasValid()
                }
              >
                {isReviewLoading ? <CircularProgress size={24} color="inherit" /> : 'Review'}
              </Button>
            </AnimateButton>
          </Stack>
        </Box>
      ) : (
        renderReviewConfig()
      )}
      {isReviewMode && (
        <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
          <Stack direction="row" justifyContent={'space-between'} spacing={2}>
            <AnimateButton>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  startTransition(() => {
                    navigate('/admin/components/targets')
                  })
                }}
              >
                Cancel
              </Button>
            </AnimateButton>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" onClick={handleBack}>
              Back
            </Button>
            <Button
              id="create-targets-button"
              variant="contained"
              onClick={handleCreateTargets}
              disabled={isLoadingCoreSettings}
            >
              Create Targets
            </Button>
          </Stack>
        </Box>
      )}
    </>
  )
}

export default CreateTargets
